import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import GaTracker from '../../../utils/GaTracker/GaTracker';

const HOST = process.env.REACT_APP_SMD_STATUS_HOST || 'http://localhost:3003';
const FAILED = 'failed';

type StatusResponseType = {
  data: {
    progress: number;
    load_profile: number[] | null;
    status: string;
    error_code?: string | null;
  };
};

export const useHistoricalLoadProfile = () => {
  const [loadProfile, setLoadProfile] = useState<number[] | null>(null);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [usingHistorical, setUsingHistorical] = useState(!!token);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const reset = () => {
    if (searchParams.has('token')) {
      searchParams.delete('token');
      history.push({
        search: searchParams.toString(),
      });
    }
    setUsingHistorical(false);
    setLoadProfile(null);
    setError(false);
    setErrorCode(null);
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    let intervalValue: NodeJS.Timeout | null = null;

    const stopInterval = () => {
      if (intervalValue) {
        clearInterval(intervalValue);
        intervalValue = null;
      }
    };

    intervalValue = setInterval(() => {
      axios
        .get(`${HOST}/status/${token}`)
        .then(({ data }: StatusResponseType) => {
          const { progress, load_profile, status, error_code = null } = data;
          setProgress(progress);
          setLoadProfile(load_profile);
          if (status === FAILED) {
            setError(true);
            setErrorCode(error_code);
            setUsingHistorical(false);
            GaTracker.trackEvent({
              category: 'Share My Data',
              action: 'Historical Data Failed to Load',
            });
            stopInterval();
          }
          if (progress >= 1) {
            GaTracker.trackEvent({
              category: 'Share My Data',
              action: 'Historical Data Loaded Successfully',
            });
            stopInterval();
          }
        })
        .catch((response) => {
          console.warn(response);
          setError(true);
          setErrorCode('UNKNOWN_ERROR');
          setUsingHistorical(false);
          stopInterval();
          GaTracker.trackEvent({
            category: 'Share My Data',
            action: 'Historical Data Failed to Load',
          });
        });
    }, 5000);

    return () => stopInterval();
  }, [token]);

  return {
    progress,
    loadProfile,
    error,
    errorCode,
    reset,
    usingHistorical,
  };
};
