// translations.ts
import * as enTranslationRateInfo from './enTranslationRateInfo';
import * as esTranslationRateInfo from './esTranslationRateInfo';
import * as zhTranslationRateInfo from './zhTranslationRateInfo';

export type TranslationType = { [key: string]: string | TranslationType };

export type TranslationsType = {
  [languageCode: string]: {
    translation: TranslationType;
  };
};

/**
 * Add new entries to the English section only. They will be merged into the
 * Spanish and Mandarin sections at runtime to fill any gaps.
 */
const translations: TranslationsType = {
  en: {
    translation: {
      languageName: 'EN',
      errorBoundary: {
        header: 'Please reset your inputs',
        body: 'We have updated our site as part of our effort to stay up-to-date with a fast changing industry, and the inputs saved to this browser are now outdated. Please reset your inputs using the button below to resolve this issue.',
        resetButton: 'Reset',
      },
      nav: {
        home: 'Home',
        rates: 'EV Rates',
        vehicles: 'Vehicles',
        compare: 'Compare',
        incentives: 'Incentives',
        maps: 'Maps',
        search: 'Search',
        stations: 'Charging Stations',
        availability: 'Station Plugs & Availability',
        route: 'Route Planner',
        range: 'Range Map',
        dealer: 'Dealer Map',
        faq: 'FAQ',
        chargingFaq: 'Charging',
        news: 'News',
        privacy: 'Privacy Policy',
        cookies: 'Cookie Settings',
        poweredBy:
          'EV solutions provided by ZappyRide, a J.D. Power business. Figures shown on this page are estimates based on data provided by third-parties. This material is provided for informational purposes only and should not be relied on for tax, legal, or financial advice. J.D. Power® does not provide tax, legal or financial advice. You should independently verify the estimates provided here before engaging in any transaction.',
        legalNotices: 'Legal Notices',
        feedback: 'Please provide feedback to help improve the EV Savings Calculator',
        copyright: '©{year} Pacific Gas and Electric Company',
        incomeQualified: 'Income Qualified',
        existingEvOwners: 'Existing EV Owners',
        vehiclesAndRates: 'Vehicles & Rates',
        allOtherIncentives: 'All Others',
      },
      evOwners: {
        intro: {
          headline: 'Maximizing your EV with PG&E',
          details: 'The most up to date info on EV ownership, incentives, charging, and more.',
          programs: 'PG&E Programs',
          rate: 'Your Best Rate',
          chargingRoute: 'Charging and Route Planning',
          upgrade: 'Thinking of Upgrading?',
        },
        incentives: {
          headline: 'Programs for your EV and home charging.',
          details:
            'PG&E has programs even after you purchase your EV.  Find out what you’re eligible for today.',
          personalizeButton: 'Personalize Incentives',
          preOwnedEvs: {
            heading: 'Pre-Owned EVs',
            description:
              'Eligible customers get up to $4,000 in rebates when purchasing or leasing a pre-owned EV.',
            title: 'Bought your EV Pre-Owned?',
          },
          homeCharging: {
            heading: 'Home Charging',
            description:
              'Helping with the cost of home charging, offering income-eligible households up to $2,500 in financial incentives.',
            title: 'Empower EV Program',
          },
          residentialChargingRebate: {
            heading: 'Residential Charging Rebate',
            title: 'Residential Charging Solutions',
            description:
              'Income-eligibile households can receive a $700 rebate on PG&E-approved EV charging equipment.',
          },
          smallBusinesses: {
            heading: 'Small Businesses',
            description: 'Install Level 1 or Level 2 chargers at your property or sites.',
            title: 'Multifamily Housing / Small Business',
          },
        },
        chargerMapLauncher: {
          headline: 'There are over 40,000 public EV chargers in California. ',
          description:
            'Use this tool to explore the vast network of charging stations, charger availability, plan your route, and more. Enter your ZIP code to get started.',
          startButton: 'Find Chargers',
          tagline: 'Find the ones near you.',
          formDetails:
            'Personalized maps and rates are available in less than 1 minute. No signup reguired.',
        },
        checkHistorical: {
          headline: 'Charge your EV at home or have solar panels? ',
          description: 'Find your best rate.',
          paragraph:
            'You may be eligible for different rates if you charge your EV at home or have solar panels. PG&E’s Use My Historical Data feature will find your best electrical rate after your EV purchase based on your actual usage.',
          button: 'Check My Historical Data',
        },
        findADealership: {
          headline1: 'Looking to upgrade your EV or need servicing?',
          headline2: 'Compare the latest and find dealerships to see what’s right for you.',
          description:
            "Discover and compare the newest electric vehicles on the market. Your next perfect match awaits at a local dealership. Click on any vehicle below to explore and compare, or find a dealership if you know what you're looking for.",
          compareEvButton: 'Compare Electric Vehicle',
          button: 'Find a Dealership',
        },
      },
      homepage: {
        intro: {
          headline: 'Welcome to the EV Savings Calculator',
          details:
            'Your electric car guide. Estimate and compare costs, savings, EV incentives, and more.',
          browse: 'Browse Electric\nVehicles',
          discover: 'Discover\nIncentives',
          compare: 'Compare\nRate Plans',
          locate: 'Locate Charging\nStations',
          existingEvOwners: 'Already own an EV?',
        },
        whyElectric: {
          headline: 'Why electric vehicles are a good idea.',
          saveMoney: 'Save money',
          saveMoneyDetails: 'Electric Vehicles can be cheaper to fuel and own than gas cars',
          hassle: 'Less hassle',
          hassleDetails:
            'Electric vehicles have no regular maintenance – no oil, filters, or belts to change',
          driveAnywhere: 'Drive anywhere',
          driveAnywhereDetails:
            'Ever-improving batteries and charging infrastructure provide freedom and peace of mind',
        },
        existingEvOwner: {
          header: 'Already drive an EV and want to maximize your experience?',
          button: 'I drive an EV',
        },
        evCosts: {
          vehicle: 'Vehicle',
          maintenance: 'Maintenance',
          insurance: 'Insurance',
          electricity: 'Electricity',
          gasoline: 'Gasoline',
          total: 'Total',
          showTableButton: 'Show graph values',
          hideTableButton: 'Hide graph values',
          seeEvButton: 'See electric vehicle',
          seeAllEvsButton: 'See All Vehicles',
          availableEvs: 'There are {vehicleCount} available electric vehicles. Discover yours.',
          compareCosts:
            'Compare the average cost of ownership to a similar gas vehicle using our calculators.',
          findEvButton: 'Find EVs that fit your needs',
          atOrNearZero: 'At or near $0',
        },
        rateCompare: {
          headline:
            'Did you know we offer a variety of electric rates? Find the best EV rate for you.',
          description:
            'This tool will clearly identify the lowest cost EV rate plan and allow you to enroll in a rate plan online.',
          startButton: 'Get Started',
          formDetails: 'Personalized rates in less than 1 minute.\n          No signup required.',
        },
        incentives: {
          headline: 'Explore potential EV incentives and tax credits',
          details:
            'See how much you could save getting behind the wheel of an EV, whether you are buying or leasing.\n            Incentives are personalized for where you live.\n          ',
          personalizeButton: 'Personalize Incentives',
        },
        news: {
          headline: 'News',
          description: 'Latest news from PG&E.',
          readMoreLink: 'Read more',
        },
        affordableEv: {
          quote:
            'A fully-charged battery in an existing, affordable EV is enough to get 87 percent of American drivers where they need to go throughout the day',
          source: 'Nature International Journal of Science',
          button: 'Discover Electric Vehicles',
        },
      },
      rates: {
        header: 'Identify the lowest cost electric rate for your EV.',
        description: 'Adjust your information below to find the right electric rate for you.',
        historicalLoading: 'Loading your Historical Usage Data',
        filtersCta: 'Personalize results',
        filters: {
          basicFilters: {
            title: 'Basic Filters',
            tooltip:
              'This calculator is optimized for those interested in switching to an EV and installing solar panels.',
            currentRateLabel: 'CURRENT RATE',
            evMakeLabel: 'ELECTRIC VEHICLE MAKE',
            evModelLabel: 'ELECTRIC VEHICLE MODEL',
            milesPerYearLabel: 'MILES DRIVEN PER YEAR',
            miles: 'Miles',
            chargingPatternLabel: 'CHARGING PATTERN',
          },
          homeAssumptions: {
            title: 'Home Assumptions',
            monthlyElectricBillLabel: 'HOME MONTHLY ELECTRIC BILL',
            perMonth: ' / month',
            usingHistoricalButton: 'Use My Historical Data',
            stopUsingHistoricalButton: 'Stop Using Historical Data',
            usingHistoricalDisclaimer: 'New Solar from above is additive to your Historical Data.',
            solarAtHomeLabel: 'Will your home have new Solar?',
            solarAtHomeDetails:
              'Solar generation can be used to offset your EV costs. For more info, visit {link}.',
            solarAtHomeDetailsLink: "PG&E's Clean Energy page",
            careDiscount: 'CARE Discount',
            careDiscountDetails:
              'CARE Discount applies to qualified low income customers. See more {careLink}.',
          },
          drivingAssumptions: {
            title: 'Driving Assumptions',
            phevPortionLabel: 'Portion of electric miles',
            gasPriceLabel: 'Gasoline price',
            fossilEfficiencyLabel: 'Conventional vehicle efficiency',
            publicChargingPortionLabel: 'Portion of public charging',
            publicChargingCostLabel: 'Cost of public charging',
            optionFree: 'Free',
            optionLow: 'Low: $0.20/kWh',
            optionMedium: 'Medium: $0.40/kWh',
            optionHigh: 'High: $0.60/kWh',
          },
          phevPortion: '{elec}% electric / {gas}% gasoline',
          gasCost: '{val} / gallon',
          mpg: 'mpg',
          yes: 'Yes',
          no: 'No',
        },
        chart: {
          title: 'Rate Comparison Without New Solar',
          historicalTitle: 'Rate Comparison Using Historical Data',
          titleWithSolar: 'Rate Comparison With New Solar',
          titleWithBothSolarAndHistorical: 'Rate Comparison, With New Solar, Using Historical Data',
          headerSavings:
            "You can save {amount} per year by switching to PG&E's {rate} rate with a {vehicle}.",
          headerExtraSpend:
            "You would spend an extra {amount} per year by switching to PG&E's {rate} rate with a {vehicle}.",
          noVehicleMessage: 'Please select a vehicle.',
          costLabel: 'Estimated average cost per year',
          gasolineToggle: 'Gasoline',
          homeToggle: 'Home',
          rateChangeButton: 'Change your rate',
          homeElec: 'Electricity for Home',
          ev: 'Electric Vehicle',
          gas: 'Gasoline',
          evPlusSolar: 'Solar + EV',
          currentRate: 'Current Rate Plan: {rate}',
          lowestRate: 'Lowest Rate: {rate}',
          withoutEv: 'Without EV',
          withEv: 'With EV',
        },
        allRates: {
          title: 'All Electric Rates',
          historicalTitle: 'All Electric Rates Using Historical Data',
          subheader:
            'Based on your selections, here are the rates you can expect if you have an EV at home.',
          subheaderWithSolar:
            'Based on your selections, here are the rates you can expect if you have an EV and Solar at home. These are estimated values, and we encourage you to explore your rate details to see if they apply.',
          noVehicleMessage: 'Please select a vehicle.',
          costLabel: 'Estimated average cost per year',
          savings: '{dollars} savings',
        },
        wizard: {
          next: 'Next',
          previous: 'Previous',
          seeResults: 'See results',
          stepProgress: 'Step {currentStep} of {totalSteps}',
          monthlyBill: {
            monthlyElectricBillTitle: 'What is your average home electric bill?',
            monthlyElectricBillLabel: 'Home monthly electric bill',
            monthlyElectricBillSuffix: ' / month',
            dollarSign: '$',
          },
          vehicle: {
            vehicleTitle: 'Vehicle',
            vehicleMakeLabel: 'Electric vehicle make',
            vehicleModelLabel: 'Electric vehicle model',
            vehicleHelperLink: 'Help Me Choose',
          },
          annualMiles: {
            milesPerYearTitle: 'On average, how many miles per year do you drive?',
            milesPerYearHelper: 'Tip: A typical Californian drives ~12,000 miles per year.',
            milesPerYearLabel: 'Miles driven per year',
            milesPerYearSuffix: 'miles',
          },
          chargingTime: {
            chargingTimeTitle: 'What charging time-frame suits you best?',
            chargingTimeHelper: 'Tip: Charging at night is the most cost-effective.',
            chargingTimeMidnight: 'Midnight ',
            chargingTimeAm: 'am ',
            chargingTimePm: 'pm ',
          },
          rate: {
            electricRateTitle: 'What is your current electric rate?',
            electricRateHelper: 'Tip: Most customers are on E-TOU-C',
          },
          summary: {
            savings: 'You can save up to {amount} per year by switching to {rate} with {vehicle}.',
            extraSpend:
              'You would spend an extra {amount} per year by switching to {rate} with {vehicle}.',
            historicalDataPrompt:
              'Get rate comparisons tailored to your unique usage with the Use My Historical Data button in the Home Assumptions section.',
          },
        },
        historicalDataModal: {
          historicalData: 'Historical Data',
          howToShareYourData: 'How to Share Your Data',
          scopeOfSharing: 'Scope of Data Sharing',
          importantNote: 'Important Note',
          multipleServiceIds:
            'If you have multiple PG&E service IDs, please select only one of them for Share My Data.',
          coolDownTime:
            'If you reset your password, please allow up to 15 minutes before logging in again.',
          howToSteps: {
            step1: 'You will be directed to the Share My Data login page.',
            step2: 'Please enter your credentials to start the Share My Data process with PG&E.',
            step3:
              'Once completed, you will be redirected back to the EV rates pages while your data is loaded.',
          },
          scopeOfData: {
            step1: '14 months of Electric meter interval and gas meter data',
            step2: 'Billing Information',
          },
          forMoreInformation: 'For more information, please visit the',
          pgeHelpCenter: 'PG&E Help Center',
          shareMyData: 'Share My Data',
        },
        historicalDataError: {
          NO_USAGE_POINTS_FOUND:
            "It seems you haven't selected an account on the Share My Data page. Please try again and choose one account to proceed.",
          BAD_USAGE_POINT_FEED_FORMAT:
            'We encountered a issue processing the data from the Share My Data system. Please try again.',
          MULTIPLE_USAGE_POINTS_FOUND:
            "It seems you've selected more than one account. Please try again and select only one account.",
          NOT_ENOUGH_DATA:
            "The selected account doesn't yet have 12 months of usage history. Please try again once more data is available.",
          UNKNOWN_ERROR:
            'We encountered an issue processing the data from the Share My Data system. Please try again.',
          alert: 'There was an error fetching historical data.',
          useEstimates: 'You can use the estimated usage from Home Assumptions or',
          tryAgain: 'try using historical data again.',
        },
        solarInputs: {
          powerLabel: 'PANEL POWER',
          powerLabelTooltip:
            'The most common solar panels installed on houses range from 150W to 500W.',
          powerSuffix: ' W / panel',
          countLabel: 'PANEL COUNT',
          countLabelTooltip: 'Homes can generally accommodate between 10 and 20 solar panels.',
          countSuffix: ' panels',
        },
        solarCard: {
          title: 'Solar Generation',
          savings:
            'You save {amount} per year in EV charging costs based on your solar set up and charging pattern.',
          link: "Solar generation is typically at it's peak between 10am and 3pm. As a customer of PG&E's Net Surplus Compensation program, excess solar energy generated can be sold to the electric grid for further savings. Please visit the {link} for more info.",
          linkText: 'Net Surplus Credit homepage',
        },
      },
      vehicleCatalog: {
        header: 'Compare Electric Vehicles',
        description:
          'Compare electric vehicles by EV range or price. Click on the EV for more details, including total cost compared to a similar gas vehicle.',
        sortBy: 'Sort By',
        showMore: 'Show More',
        numVehiclesShown: '{shown} of {total}',
        filters: 'Filters',
        sortOptions: {
          range: 'Electric Range',
          alphabetical: 'Alphabetical',
          priceLow: 'Price: Low to High',
          priceHigh: 'Price: High to Low',
        },
        filterControls: {
          title: 'Filter Results',
          range: 'Minimum Range',
          anyRange: 'Any range',
          miles: 'miles',
          budget: 'Budget After Incentives',
          personalizeIncentivesButton: 'Personalize Incentives',
          seatsMin: 'Minimum Seats',
          seats: 'seats',
          homeChargerType: 'Home Charging Availability',
          charging: {
            none: 'No Charging',
            level1: 'Level 1',
            level2: 'Level 2',
          },
          chargerHelpButton: 'Help Me Choose',
          moneySymbol: '$',
          fuel: 'Fuel',
          allElectric: 'All-Electric',
          hybrid: 'Plug-in Hybrid',
          type: 'Type',
          typeOptions: {
            sedan: 'Sedan',
            hatchback: 'Hatchback',
            coupe: 'Coupe',
            suv: 'SUV/Crossover',
            minivan: 'Minivan',
            wagon: 'Wagon',
            truck: 'Truck',
          },
          status: 'Vehicle Status',
          upcoming: 'Upcoming',
          new: 'New',
          preOwned: 'Pre-Owned',
          preOwnedDisclaimer:
            'Pre-owned vehicle data are estimates. Please Contact your EV dealer for info.',
          disclaimer:
            'Vehicles displayed may not reflect actual availability. We do not endorse or recommend any specific vehicle or car manufacturer.',
        },
        incentivesModal: {
          headline: 'Personalize Incentives',
          location: 'Location',
          zipCodeLabel: 'ZIP Code',
          taxFilingLabel: 'Tax Filing Status',
          statusOptions: {
            single: 'Single',
            married: 'Married',
            headOfHousehold: 'Head of Household',
          },
          householdSize: 'Household Size',
          householdIncome: 'Household Income',
          updateButton: 'Update incentive eligibility',
        },
        vehicleCard: {
          evRange: 'Electric Range',
          totalRange: 'Total Range',
          msrp: 'MSRP',
          rangeMiles: '{numMiles} miles',
          afterIncentives: 'After Incentives',
          matchScore: 'Match Score',
          yearRange: 'Year Range',
          averageRange: 'Average Range',
          estimatedCost: 'Estimated Cost',
          atOrNearZero: 'At or near $0',
        },
      },
      vehicleDetails: {
        notFound: 'Vehicle not found',
        header: {
          allVehicles: 'All Vehicles',
          afterIncentives: 'After Incentives',
          loanPayment: 'Loan Payment',
          downPayment: 'Down Payment',
          leasePayment: 'Lease Payment',
          perMonth: '{val} / month',
          firstLeasePayment: 'First Lease Payment',
          incentivesForLease: 'Incentives for Lease',
          msrp: 'MSRP',
          estimatedIncentives: 'Estimated Incentives',
          allCarsLink: 'View all cars',
          findDealersLink: 'Find dealers',
          year: 'Year',
          used: 'Used',
          usedAfterIncentives: 'Estimated After Incentives*',
          usedMsrp: 'Estimated Price*',
          usedDisclaimer:
            '* Pre-owned EVs may range in price depending on mileage, condition, availability, etc. For the most up to date information, please contact your EV dealer. Learn more about owning an EV from the {link}.',
          usedDisclaimerLink: 'PG&E Electric Vehicle Checklist',
          atOrNearZero: 'At or near $0',
          msrpStar: 'MSRP*',
          noIncentivesExplainer:
            '* For more information on currently available incentives, please visit the {link} page.',
        },
        specs: {
          type: 'Type',
          fuelType: 'Fuel type',
          milesPerThirtyCharge: 'Miles per 30 min of fast charging',
          batterySize: 'Battery size',
          treesPlanted: 'Trees planted',
          trees: '{val} trees',
          zeroToSixty: '0-60mph acceleration',
          seats: 'Seats',
          seatsRange: '{low} to {high}',
          range: 'Electric range',
          chargeTimeL2: 'Time to charge - Level 2',
          co2Reduction: 'CO2 emissions reduction',
          gasSaved: 'Gasoline saved',
          drivetrain: 'Drivetrain',
          kwh: '{val} kWh',
          seconds: '{val} seconds',
          miles: '{val} miles',
          estMiles: '~ {val} miles',
          lbsPerYr: '{val} lbs/yr',
          galPerYr: '{val} gal/yr',
          fuelTypes: {
            gasoline: 'Gasoline',
            electric: 'Electric',
            electricAndGasoline: 'Electric and gasoline',
          },
          drivetrainTypes: {
            '4wheelDrive': '4-Wheel Drive',
            rearwheelDrive: 'Rear-Wheel Drive',
            allwheelDrive: 'All-Wheel Drive',
            frontwheelDrive: 'Front-Wheel Drive',
          },
          na: 'N/A',
          comingSoon: 'Coming soon!',
        },
        costsSmallScreenDisclaimer:
          'For more details including specs & calculations, please view this page on desktop.',
        incentivesHeader:
          'Up to {amount} in tax credits and rebates are potentially available for the {vehicle}',
        maps: {
          chargingStations: 'Charging Stations',
          rangeMap: 'Range Map',
          routePlanner: 'Route Planner',
          dealerMap: 'Dealer Map',
        },
      },
      compare: {
        header: 'Compare New Vehicles',
        subheader: 'Pick two vehicles to begin the comparison.',
        alternativeVehicle: 'Please Select Comparison Vehicle',
        smallScreenDisclaimer:
          'For more details including specs & calculations, please view this page on desktop.',
        selectVehicle: {
          fuelType: 'Fuel Type',
          carMake: 'Car Make',
          carModel: 'Car Model',
        },
        pricePanel: {
          afterIncentives: 'After Incentives',
          msrp: 'MSRP',
          estimatedIncentives: 'Estimated Incentives',
          availableIncentives: '{incentivesQuantity} available incentives >',
        },
        specs: {
          carMake: 'Car Make',
          carModel: 'Car Model',
          vehicleType: 'Vehicle Type',
          fuelType: 'Fuel Type',
          batterySize: 'Battery Size',
          totalRange: 'Total Range',
          timeToChargeLevel2: 'Time to Charge - Level 2',
          milesPer30MinFastCharge: 'Miles Per 30 Min of Fast Charging',
          emissionsReduction: 'CO2 Emissions Reduction',
          viewDetails: 'View {vehicleDisplayName}',
          gasoline: 'Gasoline',
          electric: 'Electric',
          electricAndGasoline: 'Electric and gasoline',
        },
        filters: {
          purchaseMethod: 'Purchase Method',
          annualMiles: 'Miles Driven Annually',
          phevElectricPortion: 'Portion Electric for PHEV',
          yearsOfOwnership: 'Years of Ownership/Lease',
          interestRate: 'Interest Rate',
          gasPrice: 'Gasoline Price ($/Gal)',
          includeResale: 'Include Vehicle Resale',
        },
        assumptions: {
          header: 'Assumptions',
          electricityRate:
            'Electricity rate is calculated as the average for six months of summer off-peak and six months of winter off-peak for {rateLink}.',
          equivalentVehicleDisclaimer:
            'We selected the {vehicle2} based on its proximity to the {vehicle1} based on brand, size, and price.',
          fastChargingSpeed: 'Fast Charging Speed',
          level2ChargingSpeed: 'Level 2 Charging Speed',
          chargeRate: '{rate} kW',
          maxChargeRateDisclaimer:
            'The {vehicle} is only able to charge at a maximum of {chargeRate}kW.',
          normalChargeRateDisclaimer: 'The {vehicle} is assumed to charge at {chargeRate}kW.',
          range: 'Range based on EPA estimates.',
          salesTax: 'Sales Tax',
          downPayment: 'Down Payment',
          downPaymentValue: '{percent}% of MSRP + Tax',
          electricityRateLabel: 'Electricity Rate',
        },
        charts: {
          header: 'Compare the {vehicle1Link} to the {vehicle2Link}',
          fuelCostComparisonExpensive:
            'The {vehicle} is {costDelta} more expensive to fill up monthly',
          fuelCostComparisonCheap: 'The {vehicle} is {costDelta} cheaper to fill up monthly',
          expensive:
            'The {vehicle} is {costDelta} more expensive to own over {ownershipYears} years',
          cheap: 'The {vehicle} is {costDelta} cheaper to own over {ownershipYears} years',
          vehicleCost: 'Vehicle net Incentives',
          electricity: 'Electricity',
          gasoline: 'Gasoline',
          maintenance: 'Maintenance',
          insurance: 'Insurance',
          tooltipVehicles:
            'Electric vehicles are currently often more expensive than traditional internal combustion engines. However, some of this upfront cost can be offset by vehicle incentives, lower costs of ownership, and stronger resale value',
          tooltipElectricity:
            'The cost of electricity is significantly cheaper than that of gasoline.',
          tooltipGasoline:
            'Gasoline is a more expensive fuel for your vehicle. Over time, this expense can become even more pronounced.',
          tooltipMaintenance:
            'Maintenence costs are significantly lower for Electric Vehicles due to less moving parts.',
          tooltipInsurance:
            'With the higher costs of electric vehicles, insurance for the vehicle is also often higher.',
          paybackPeriodHeader:
            'Compare the cumulative lifetime cost of the {vehicle1} to a {vehicle2}',
          showGraphValues: 'Show graph values',
          hideGraphValues: 'Hide graph values',
          cashPurchaseDisclaimer:
            'Note: Breakeven chart assumes the vehicles are purchased in cash',
          yearHeader: 'Year',
          year: 'Year {numYear}',
          fastChargingSameMiles:
            'The {vehicle1} and {vehicle2} charge the same number of miles per 30 minutes of fast charging',
          fastChargingMoreMiles:
            'The {vehicle} charges the most miles per 30 minutes of fast charging',
          level2ChargingSameRate:
            'The {vehicle1} and {vehicle2} charge to full capacity with a level 2 charger at the same rate',
          level2ChargingFaster:
            'The {vehicle} charges to full capacity faster with a level 2 charger',
          sameRange: 'The {vehicle1} and {vehicle2} have the same range',
          longerRange: 'The {vehicle} has the longest range',
          costChart: {
            selectionLabel: 'Your EV Selection',
            similarLabel: 'Similar Gas Vehicle',
            toggleCostBreakdown: 'Show Cost Breakdown',
          },
        },
        table: {
          purchase: {
            title: 'Vehicle',
            purchaseMethod: 'Purchase Method',
            downPayment: 'Down Payment',
            assumption: 'Assumption: {assumption}',
            loan: 'Loan Amount',
            loanExplainer: 'MSRP * (1 + Sales Tax) - Down Payment',
            monthlyPayments: 'Monthly Payments',
            monthlyPaymentsExplainer:
              'Financial function of Loan Amount, Interest Rate, and Months of Ownership',
            monthsOfOwnership: 'Months of Ownership',
            totalMonthlyPayments: 'Total Monthly Payments',
            totalMonthlyPaymentsExplainer: 'Monthly Payment * Months of Ownership',
            salesTax: 'Sales Tax',
            incentives: 'Incentives',
            capitalizedIncentives: 'Capitalized Incentives',
            capitalizedIncentivesExplainer: 'incentives capitalized within the lease',
            capitalizedCost: 'Capitalized Cost',
            capitalizedCostExplainer: 'MSRP - Down Payment - Capitalized Incentives',
            monthlyDepreciation: 'Monthly Depreciation Cost',
            monthlyDepreciationExplainer: '(Capitalized Cost - Resale Value) / Months of Ownership',
            moneyFactor: 'Money Factor',
            moneyFactorExplainer: 'Equivalent to user input of {interestRate}',
            monthlyFinancing: 'Monthly Financing Cost',
            monthlyFinancingExplainer: '(Capitalized Cost + Resale Value) * Money Factor',
            monthlyLease: 'Monthly Lease Payment',
            monthlyLeaseExplainer:
              '(Monthly Depreciation Cost + Monthly Financing Cost) * (1 + Sales Tax)',
            firstLease: 'First Lease Payment',
            firstLeaseExplainer: 'Down Payment + Monthly Lease Payment',
            nonCapitalizedLeaseIncentives: 'Non-capitalized Lease Incentives',
            nonCapitalizedLeaseIncentivesExplainer:
              'Incentives not captured in capitalized lease cost',
            resale: 'Resale Value',
            resaleExplainer:
              'Function of vehicle MSRP and lifetime miles of {numMiles}, assuming negative exponential depreciation with distance where resale value is 60% after 36,000 miles',
            totalCost: 'Total Vehicle Cost',
            totalCostExplainerCash: 'Vehicle MSRP * (1 + Sales Tax) - Incentives - Resale Value',
            totalCostExplainerLoan:
              'Down Payment + Total Monthly Payments - Incentives - Resale Value',
            totalCostExplainerLease:
              'First Lease Payment + (Months Of Ownership - 1) * (Monthly Lease Payment) - Non-capitalized Lease Incentives',
          },
          electricity: {
            title: 'Electricity',
            rateExplainer:
              'Average for six months of summer off-peak and six months of winter off-peak for {rateLink}',
            efficiency: "Vehicle's kWh per 100 mi",
            efficiencyExplainer: 'EPA Efficiency Rating',
            costPer100Miles: '$/100 mi driven',
            costPer100MilesExplainer: "Electricity Cost * Vehicle's kWh per 100 mi",
            totalElectricityCost: 'Total Electricity Cost',
            totalElectricityCostExplainer: '$/100 mi driven * Lifetime Miles / 100',
            totalElectricityCostExplainerPHEV:
              '$/100 mi driven * Lifetime Miles * Portion of Electric Driving / 100',
          },
          gasoline: {
            title: 'Gasoline',
            gasolineCost: 'Gasoline Cost',
            mpgLabel: "Vehicle's MPG (miles per gallon)",
            mpg: '{mpg} miles/gal',
            totalGasolineCost: 'Total Gasoline Cost',
            totalGasolineCostExplainer: 'Gasoline Cost * Lifetime Miles / MPG',
            totalGasolineCostExplainerPHEV:
              'Gasoline Cost * Lifetime Miles  * (1 - Portion of Electric Driving) / MPG',
          },
          maintenance: {
            title: 'Maintenance',
            maintenancePerMileGas: 'Maintenance per mile for gasoline',
            maintenancePerMileGasExplainer:
              'Extrapolation of average maintenance cost of {costPerMile}, driven by vehicle MSRP in relation with average MSRP of {avgMsrp}',
            evCostReduction: 'EV Maintenance Cost Reduction',
            consumerReports: 'Consumer Reports',
            maintenancePerMileElec: 'Maintenance per mile for electricity',
            maintenancePerMileElecExplainer:
              'Maintenance per mile for gasoline * EV Maintenance Reduction Factor',
            blendedMaintenancePerMile: 'Blended maintenance cost per mile',
            blendedMaintenancePerMileExplainer:
              'Portion of Electric Driving applied to Maintenance per mile for gasoline and for electricity',
            totalMaintenanceCost: 'Total Maintenance Cost',
            totalMaintenanceCostExplainer: 'Maintenance cost per mile * Lifetime Miles',
          },
          insurance: {
            title: 'Insurance',
            avgInsurancePerYear: 'Average Insurance per Year',
            insurancePerYear: 'Insurance per Year',
            insurancePerYearExplainer:
              'Extrapolation of average insurance cost in California, driven by vehicle MSRP in relation with average MSRP of {avgMsrp}',
            yearsOfOwnership: 'Years of Ownership',
            totalInsuranceCost: 'Total Insurance Cost',
            totalInsuranceCostExplainer: 'Insurance cost per year * Years of Ownership',
            avgInsuranceCostText: 'Average Insurance Cost In California',
          },
          description: 'Description',
          source: 'Source',
          userInput: 'User input',
          msrp: 'Vehicle MSRP',
          lifetimeMiles: 'Lifetime Miles',
          phevPortion: 'Portion of Electric Driving',
          phevPortionExplainer:
            'User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.',
          showSources: 'Show sources',
          hideSources: 'Hide sources',
          ariaLabel: 'Calculation sources',
          salesTaxSourceText: 'Statewide Base Sales and Use Tax Rate',
        },
      },
      incentives: {
        headline: 'Electric Vehicle Incentives',
        description:
          'You may be eligible for a range of incentives, including EV rebates, EV tax credits, and various other benefits. Incentives are personalized for where you live.',
        selectVehicleError:
          'Please select a vehicle make & model on the left hand panel to see incentives.',
        selectVehicleErrorSmallScreen:
          'Please select a vehicle make & model in the Filters dialog to see incentives.',
        zipcodeError: 'Please enter a valid zipcode.',
        eligibleHeadline:
          'You are eligible for a total of {eligibleAmount} in rebates and tax credits',
        maybeHeadline: 'You may be eligible for',
        ineligibleHeadline: 'Based on your inputs, you are not eligible for',
        filters: 'Filters',
        eligibilityFilters: {
          headline: 'Eligibility Filters',
          zipcode: 'ZIP Code',
          provider: 'Electricity Provider',
          income: 'Household Income',
          sizeOfHousehold: 'Size of Household',
          taxFilingStatus: 'Tax Filing Status',
          retiringVehicle: 'Retiring a Vehicle',
          taxStatuses: {
            single: 'Single',
            joint: 'Married',
            headOfHousehold: 'Head of Household',
          },
        },
        vehicleFilters: {
          headline: 'Electric Vehicle Filters',
          make: 'Make',
          model: 'Model',
          status: 'Vehicle Status',
          year: 'Year',
        },
      },
      maps: {
        header: 'Locate EV Charging Stations',
        stations: 'Charging Stations',
        range: 'Range Map',
        route: 'Route Planner',
        dealer: 'Dealer Map',
        availability: 'Station Plugs & Availability',
        psps: 'A PSPS event may be happening. Please see the {link} to verify.',
        pspsLink: 'official PSPS page',
        pspsBanner:
          'Public Safety Power Shutoff in effect. Please see the official PSPS page {link}.',
        pspsBannerError: 'A PSPS event may be happening. Please see the {link} to verify.',
        pspsBannerLink: 'here',
        pspsBannerErrorLink: 'official PSPS page',
        pspsEventArea: 'PSPS event area',
        chargingStationNetworkUnavailable: 'Link to Network Unavailable',
        dealerMap: {
          showButton: 'Full list of dealers',
          hideButton: 'Hide list of dealers',
          contact: 'Contact',
          website: 'Website',
        },
        stationsMap: {
          showButton: 'Full list of stations',
          hideButton: 'Hide list of stations',
          publicStationTitle: 'Power Stations',
          publicStationDescription: 'Level 2 Chargers',
          dcfcStationTitle: 'High Power Stations',
          dcfcStationDescription: 'DC fast chargers',
          privateStationTitle: 'Other Stations',
          privateStationDescription: 'Private stations',
        },
        availabilityMap: {
          searchChargingStations: 'Search Charging Stations',
          availabilityStationTitle: 'Availability & Plug Compatibility',
          availabilityStationDescription: 'Has plug type & availability data.',
          plugOnlyStationTitle: 'Plug Compatibility',
          plugOnlyStationDescription: 'Has plug type data.',
          consent: 'By using this service you consent to these ',
          consentLink: 'terms of use',
          disclaimer:
            'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {link}.',
        },
        travelRadiusMap: {
          viewTravelRadius: 'View Travel Radius',
          disclaimer:
            'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model and automobile conditions.',
          fullBatteryRange: '{currentElectricRange} mile electric range, full battery',
          fullGasRange: '{currentTotalRange} mile total range, full tank of gas',
        },
        routeMap: {
          disclaimer:
            'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {link}.',
        },
        dealersMap: {
          searchQualifiedDealers: 'Search Dealers',
          brands: 'Brands',
          dealers: 'Dealers',
          allBrands: 'All Brands',
          allDealers: 'All Dealers',
        },
      },
      chargerDetails: {
        plugType: 'Plug Type',
        plugAvailability: 'Availability',
        backToMap: 'Station Plugs & Availability',
        routeToChargerButton: 'Route to Charger',
        chargerWebsiteButton: 'Charger Website',
        routeToChargerHeadline: 'Route To Charger',
        estimatedChargingCost: {
          headline: 'Charging Cost Estimator',
          makeLabel: 'Vehicle Make',
          modelLabel: 'Vehicle Model',
          costPerKwhLabel: '$/kWh',
          result: 'Charge to Full',
          vehicleLink: 'Vehicle Details',
          disclaimer: 'This estimate is an approximation and is not guaranteed.',
        },
        routeMap: {
          startLocationLabel: 'Start Location',
          endLocationLabel: 'End Location',
          mapRouteButton: 'Map Route',
          routeDistance: 'This route is {distanceInMiles} miles',
          stationFetchError: 'There was an error fetching charging stations. Please try again.',
          routeCreateError:
            'We could not find that route. Please check the addresses and try again.',
          highPoweredStationsOnly: 'High Power Stations Only',
        },
      },
      news: {
        header: 'News',
        readMore: 'Read More →',
        error: 'Sorry, there was an issue loading news stories.',
      },
      search: {
        searchInputLabel: 'Search',
        searchInputPlaceholder: 'Search for vehicles, incentives, news…',
        filterTitle: 'Filter Results',
        resultsCount: '{count} results',
        viewMore: 'View more',
        viewLess: 'View less',
        vehiclesTitle: 'Vehicles',
        noMatchingVehicles: 'There are no matching vehicles.',
        linkAllVehicles: 'View all vehicles.',
        incentivesTitle: 'Incentives',
        noMatchingIncentives: 'There are no matching incentives.',
        linkAllIncentives: 'View all incentives.',
        articlesTitle: 'News',
        noMatchingArticles: 'There are no matching news articles.',
        linkAllArticles: 'View all news articles.',
      },
      incomeQualified: {
        header: {
          title: 'Electric Vehicles are a solution for everyone',
          subtitle:
            'Learn about affordable options, specific rates and programs, and tools catered for Income Qualified customers.',
        },
        whyElectric: {
          headline: 'Why electric vehicles are affordable.',
          preOwnedEvs: 'Pre-Owned EVs',
          preOwnedEvsDetails:
            'The pre-owned EV market has tripled in size and prices have fallen significantly.',
          lowerOperating: 'Lower Operating Costs',
          lowerOperatingDetails:
            'Electric vehicles are cheaper to fuel and maintain than gas cars.',
          programsAndIncentives: 'Programs & Incentives',
          programsAndIncentivesDetails:
            'Numerous PG&E, state, and federal programs are available to further reduce EV costs.',
        },
        evCosts: {
          vehicle: 'Vehicle',
          maintenance: 'Maintenance',
          insurance: 'Insurance',
          electricity: 'Electricity',
          gasoline: 'Gasoline',
          total: 'Total',
          showTableButton: 'Show graph values',
          hideTableButton: 'Hide graph values',
          seeEvButton: 'See electric vehicle',
          findEvButton: 'Find affordable pre-owned EVs',
          availableEvs:
            'There are {vehicleCount} pre-owned electric vehicles under $40,000. Discover yours.',
          compareCosts:
            'Compare the average cost of ownership to a similar gas vehicle using our calculators.',
        },
        financialAssistance: {
          headline: 'We offer CARE, FERA and many other financial assistance programs.',
          description:
            'PG&E is committed to supporting customers and making operating EVs affordable. Learn about our California Alternate Rebates for Energy and Family Electric Rates Assistance to join the 1.4 million customers receiving bill discounts.',
          formDetails: 'Check eligibility for multiple programs and apply in multiple languages.',
          careAndFera: 'CARE & FERA',
          financialAssistance: 'Financial Assistance',
        },
        incentives: {
          headline: 'Explore Incentives for Income Qualified Customers',
          details:
            'There are numerous PG&E, state, and federal incentives for income qualified customers.\nIncentives are personalized for where you live, income, and size of household.',
          personalizeButton: 'Personalize Income Qualified Incentives',
        },
        mapLauncher: {
          headline: 'Get to where you need to go',
          description:
            'Public EV chargers are widely available, and EV range has grown significantly. Use our Maps tools to find charging stations close to your home and work, or to find chargers along the way.',
          startButton: 'Find Public Chargers',
          formDetails:
            'Find chargers in your area in less than 1 minute.\n          No signup required.',
        },
      },
      feedback: {
        title: 'Feedback',
        whatIsGoal: 'What is your goal of visiting ev.pge.com?',
        didYouAchieveGoal: 'Did you achieve the goal of your visit?',
        yes: 'Yes',
        no: 'No',
        howWouldYouImprove: 'How would you improve this tool?',
        submit: 'Submit',
      },
      error: {
        header: 'Error',
      },
      npsScore: {
        prompt: 'Would you recommend this tool?',
        notLikely: 'Not Likely',
        veryLikely: 'Very Likely',
        selectResponse: 'Select your response',
      },
      rateInfo: enTranslationRateInfo,
      common: {
        address: 'Address',
        default: 'default',
        miles: 'Miles',
        submit: 'Submit',
        submitting: 'Submitting...',
        vehicle: 'Vehicle',
        years: 'Years',
        chargingPeriods: {
          afterMidnightBefore3pm: 'After Midnight; Before 3PM',
          after9pmBefore4pm: 'Before 4pm or After 9pm',
          after4pmBefore9pm: 'After 4PM; Before 9PM',
          all: 'All Hours',
          unpredictable: 'All Hours (Unpredictable)',
        },
        purchaseMethods: {
          cash: 'Cash',
          loan: 'Loan',
          lease: 'Lease',
        },
        zipInput: {
          label: 'ZIP Code',
          placeholder: 'Enter ZIP Code',
          button: 'Update ZIP Code',
          error: 'Invalid ZIP Code, expecting 5 digits',
          invalid: 'Invalid ZIP Code',
        },
      },
    },
  },
  es: {
    translation: {
      languageName: 'ES',
      errorBoundary: {
        header: 'Restablezca sus entradas',
        body: 'Ha modificado una URL válida, o hemos actualizado los datos de nuestros vehículos como parte de nuestro esfuerzo para mantenernos al tanto con una industria que cambia rápidamente. Restablezca sus entradas usando el botón a continuación para resolver este problema.',
        resetButton: 'Reiniciar',
      },
      nav: {
        home: 'Inicio',
        rates: 'Tarifas VE',
        vehicles: 'Vehículos',
        compare: 'Comparar',
        incentives: 'Incentivos',
        maps: 'Mapas',
        stations: 'Estaciones de Carga',
        availability: 'Disponibilidad de Estaciones',
        route: 'Planeador de Ruta',
        range: 'Mapa de Rango',
        dealer: 'Mapa del Concesionario',
        faq: 'Preguntas más Frecuentes',
        chargingFaq: 'Carga',
        news: 'Noticias',
        privacy: 'Política de Privacidad',
        cookies: 'Configuración de Cookies Web',
        poweredBy: 'Impulsado por © J.D. Power ZappyRide',
        feedback:
          'Proporcione sus comentarios para ayudar a mejorar la calculadora de ahorros de VE',
        copyright: '© {year} Pacific Gas and Electric Company',
        search: 'Buscar',
      },
      homepage: {
        intro: {
          headline: 'Bienvenido a la Calculadora de Ahorros de Vehículos Eléctricos',
          details:
            'Su guía de vehículos eléctrico. Estime y compare los costos, ahorros, incentivos de VE y más.',
          browse: 'Explorar Vehículos Eléctricos',
          discover: 'Descubrir\nIncentivos',
          compare: 'Comparar\nPlanes de Tasas Eléctricas',
          locate: 'Localizar Estaciones de Carga',
        },
        whyElectric: {
          headline: 'Por qué los vehículos eléctricos son una buena idea.',
          saveMoney: 'Ahorre dinero',
          saveMoneyDetails:
            'Los vehículos eléctricos pueden ser más baratos de combustible y poseer que los autos de gasolina',
          hassle: 'Menos molestias',
          hassleDetails:
            'Los vehículos eléctricos no tienen mantenimiento regular: ni aceite, filtros o cinturones para cambiar',
          driveAnywhere: 'Conducir a cualquier parte',
          driveAnywhereDetails:
            'Las baterías siempre mejoradas y la infraestructura de carga proporcionan libertad y tranquilidad',
        },
        evCosts: {
          vehicle: 'Vehículo',
          maintenance: 'Mantenimiento',
          insurance: 'Seguro',
          electricity: 'Electricidad',
          gasoline: 'Gasolina',
          total: 'Total',
          showTableButton: 'Mostrar valores de gráficos',
          hideTableButton: 'Ocultar valores de gráficos',
          seeEvButton: 'Ver vehículo eléctrico',
          seeAllEvsButton: 'Ver todos los vehículos',
          availableEvs: 'Hay {vehicleCount} vehículos eléctricos disponibles. Descubre el tuyo.',
          compareCosts:
            'Compare el costo promedio de propiedad con un vehículo de gas similar utilizando nuestras calculadoras.',
          findEvButton: 'Encuentra EV que se ajusten a tus necesidades',
        },
        rateCompare: {
          headline:
            '¿Sabía que ofrecemos una variedad de tarifas eléctricas? Encuentra la mejor tasa de EV para ti.',
          description:
            'Esta herramienta identificará claramente el plan de tarifas EV de menor costo y le permitirá inscribirse en un plan de tarifas en línea.',
          startButton: 'Empezar',
          formDetails:
            'Tarifas personalizadas en menos de 1 minuto.\n          No se requiere registro.',
        },
        incentives: {
          headline: 'Explore posibles incentivos de EV y créditos fiscales',
          details:
            'Vea cuánto podría ahorrar al volante de un EV, ya sea que esté comprando o arrendando.\n            Los incentivos son personalizados para donde vive.\n          ',
          personalizeButton: 'Personalizar incentivos',
        },
        news: {
          headline: 'Noticias',
          description: 'Últimas noticias de PG&E.',
          readMoreLink: 'Lee mas',
        },
        affordableEv: {
          quote:
            'Una batería totalmente cargada en un EV existente y asequible es suficiente para obtener el 87 por ciento de los conductores estadounidenses a donde necesitan ir durante todo el día',
          source: 'Nature International Journal of Science',
          button: 'Descubra vehículos eléctricos',
        },
      },
      rates: {
        header: 'Identifique la tarifa eléctrica de menor costo para su EV.',
        description:
          'Ajuste su información a continuación para encontrar la tarifa eléctrica adecuada para usted.',
        filtersCta: 'Personalizar resultados',
        filters: {
          basicFilters: {
            title: 'Filtros básicos',
            currentRateLabel: 'TASA ACTUAL',
            evMakeLabel: 'Vehículo eléctrico Make',
            evModelLabel: 'Modelo de vehículo eléctrico',
            milesPerYearLabel: 'Millas conducidas por año',
            miles: 'Millas',
            chargingPatternLabel: 'Patrón de carga',
          },
          homeAssumptions: {
            title: 'Suposiciones en el hogar',
            monthlyElectricBillLabel: 'Factura de electricidad mensual de inicio',
            perMonth: ' / mes',
            solarAtHomeLabel: '¿Tienes energía solar en casa?',
            solarAtHomeDetails:
              'El perfil de carga típico cambia drásticamente si se instala la energía solar',
          },
          drivingAssumptions: {
            title: 'Suposiciones de conducción',
            phevPortionLabel: 'Porción de millas eléctricas',
            gasPriceLabel: 'Precio de gasolina',
            fossilEfficiencyLabel: 'Eficiencia convencional del vehículo',
            publicChargingPortionLabel: 'Parte del cargo público',
            publicChargingCostLabel: 'Costo de carga pública',
            optionFree: 'Libre',
            optionLow: 'Bajo: $ 0.20/kWh',
            optionMedium: 'Medio: $ 0.40/kWh',
            optionHigh: 'Alto: $ 0.60/kWh',
          },
          phevPortion: '{elec}% electric / {gas}% gasolina',
          gasCost: '{val} / galón',
          mpg: 'mpg',
          yes: 'Sí',
          no: 'No',
        },
        chart: {
          title: 'Comparación de tasas',
          headerSavings:
            'Puede ahorrar {amount} por año cambiando a la tasa de PG&E {rate} con un {vehicle}.',
          headerExtraSpend:
            'Gastaría una {amount} adicional por año cambiando a la tasa de {rate} de PG&E con un {vehicle}.',
          noVehicleMessage: 'Seleccione un vehículo.',
          costLabel: 'Costo promedio estimado por año',
          gasolineToggle: 'Gasolina',
          homeToggle: 'Inicio',
          rateChangeButton: 'Cambia tu tasa',
          homeElec: 'Electricidad para el hogar',
          ev: 'Vehículo eléctrico',
          gas: 'Gasolina',
          currentRate: 'Plan de tarifa actual: {rate}',
          lowestRate: 'Tasa más baja: {rate}',
          withoutEv: 'Sin VE',
          withEv: 'Con VE',
        },
        allRates: {
          title: 'Todas las Tarifas Eléctricas',
          noVehicleMessage: 'Seleccione un vehículo.',
          costLabel: 'Costo promedio estimado por año',
          savings: '{dollars} ahorros',
        },
        wizard: {
          next: 'Próximo',
          previous: 'Anterior',
          seeResults: 'Ver resultados',
          stepProgress: 'Paso {currentStep} de {totalSteps}',
          monthlyBill: {
            monthlyElectricBillTitle: '¿Cuál es su factura eléctrica promedia?',
            monthlyElectricBillLabel: 'Factura de electricidad mensual',
            monthlyElectricBillSuffix: ' / mes',
            dollarSign: 'ps',
          },
          vehicle: {
            vehicleTitle: 'Vehículo',
            vehicleMakeLabel: 'Marca de vehículo eléctrico',
            vehicleModelLabel: 'Modelo de vehículo eléctrico',
            vehicleHelperLink: 'Ayúdame a Elegir',
          },
          annualMiles: {
            milesPerYearTitle: 'En promedio, ¿cuántas millas por año conduce?',
            milesPerYearHelper: 'Consejo: Un típico californiano impulsa ~ 12,000 millas por año.',
            milesPerYearLabel: 'Millas conducidas por año',
            milesPerYearSuffix: 'millas',
          },
          chargingTime: {
            chargingTimeTitle: '¿Qué carga de tiempo de carga te queda mejor?',
            chargingTimeHelper: 'Consejo: Cargar por la noche es el más rentable.',
            chargingTimeMidnight: 'Doce de la noche ',
            chargingTimeAm: 'am ',
            chargingTimePm: 'pm ',
          },
          rate: {
            electricRateTitle: '¿Cuál es su tarifa eléctrica actual?',
            electricRateHelper: 'Consejo: la mayoría de los clientes están en E-TOU-C',
          },
          summary: {
            savings: 'Puede ahorrar hasta {amount} por año cambiando a {rate} con {vehicle}.',
            extraSpend: 'Gastaría una {amount} adicional por año cambiando a {rate} con {vehicle}.',
          },
        },
      },
      vehicleCatalog: {
        header: 'Comparar vehículos eléctricos',
        description:
          'Compare autos eléctricos por rango EV o precio. Haga clic en el EV para obtener más detalles, incluido el costo total en comparación con un vehículo de gas similar.',
        sortBy: 'Ordenar Por',
        showMore: 'Mostrar Más',
        numVehiclesShown: '{shown} de {total}',
        filters: 'Filtros',
        sortOptions: {
          range: 'Rango Electrico',
          alphabetical: 'Alfabético',
          priceLow: 'Precio: Bajo a Alto',
          priceHigh: 'Precio: Alto a Bajo',
        },
        filterControls: {
          title: 'Filtrar Resultados',
          range: 'Rango',
          miles: 'millas',
          budget: 'Presupuesto Después de Incentivos',
          personalizeIncentivesButton: 'Personalizar Incentivos',
          seatsMin: 'Asientos Mínimos',
          seats: 'asientos',
          homeChargerType: 'Disponibilidad de Carga en el Hogar',
          charging: {
            none: 'Sin Cargar',
            level1: 'Nivel 1',
            level2: 'Nivel 2',
          },
          chargerHelpButton: 'Ayúdame a Elegir',
          moneySymbol: '$',
          fuel: 'Combustible',
          allElectric: 'Totalmente Eléctrico',
          hybrid: 'Híbrido Enchufable',
          type: 'Tipo',
          typeOptions: {
            sedan: 'Sedán',
            hatchback: 'Hatchback',
            coupe: 'Cupé',
            suv: 'SUV/Crossover',
            minivan: 'Minivan',
            wagon: 'Vagón',
            truck: 'Camión',
          },
          status: 'Estado del Vehículo',
          upcoming: 'Próximo',
          disclaimer:
            'Los vehículos mostrados pueden no reflejar la disponibilidad real. No respaldamos ni recomendamos ningún fabricante específico de vehículos o automóviles.',
        },
        incentivesModal: {
          headline: 'Personalizar Incentivos',
          location: 'Ubicación',
          zipCodeLabel: 'Código Postal',
          taxFilingLabel: 'Estado de presentación de impuestos',
          statusOptions: {
            single: 'Único',
            married: 'Casado',
            headOfHousehold: 'Jefe de Hogar',
          },
          householdSize: 'Tamaño del Hogar',
          householdIncome: 'Ingresos del Hogar',
          updateButton: 'Actualizar la elegibilidad para incentivos',
        },
        vehicleCard: {
          evRange: 'Rango Electrico',
          totalRange: 'Rango Total',
          msrp: 'MSRP',
          rangeMiles: '{numMiles} millas',
          afterIncentives: 'Después de los Incentivos',
          matchScore: 'Puntaje',
        },
      },
      vehicleDetails: {
        notFound: 'Vehículo no encontrado',
        header: {
          allVehicles: 'Todos los Vehiculos',
          afterIncentives: 'Después de los Incentivos',
          loanPayment: 'Pago de Préstamo',
          downPayment: 'Depósito',
          leasePayment: 'Pago de Arrendamiento',
          perMonth: '{val} / mes',
          firstLeasePayment: 'Primer pago de Arrendamiento',
          incentivesForLease: 'Incentivos para el Arrendamiento',
          msrp: 'Precio',
          estimatedIncentives: 'Incentivos Estimados',
          allCarsLink: 'Ver todos los autos',
          findDealersLink: 'Encontrar Concesionarios',
        },
        specs: {
          type: 'Tipo',
          fuelType: 'Tipo de Combustible',
          milesPerThirtyCharge: 'Millas por 30 minutos de carga rápida',
          batterySize: 'Tamaño de la batería',
          treesPlanted: 'Árboles plantados',
          trees: '{val} árboles',
          zeroToSixty: 'Aceleración de 0-60 mph',
          seats: 'Asientos',
          seatsRange: '{low} a {high}',
          range: 'Rango electrico',
          chargeTimeL2: 'Tiempo de carga - Nivel 2',
          co2Reduction: 'Reducción de emisiones de CO2',
          gasSaved: 'Gasolina ahorrada',
          drivetrain: 'Transmisión',
          kwh: '{val} kwh',
          seconds: '{val} segundos',
          miles: '{val} millas',
          estMiles: '~ {val} millas',
          lbsPerYr: '{val} lbs/año',
          galPerYr: '{val} gal/año',
          fuelTypes: {
            gasoline: 'Gasolina',
            electric: 'Eléctrico',
            electricAndGasoline: 'Electricidad y gasolina',
          },
          drivetrainTypes: {
            '4wheelDrive': 'Tracción en 4 Ruedas',
            rearwheelDrive: 'Tracción Trasera',
            allwheelDrive: 'Tracción Total',
            frontwheelDrive: 'Tracción Delantera',
          },
        },
        costsSmallScreenDisclaimer:
          'Para obtener más detalles, incluidas especificaciones y cálculos, vea esta página en el escritorio.',
        incentivesHeader:
          'Hasta {amount} en créditos fiscales y reembolsos están potencialmente disponibles para el {vehicle}',
        maps: {
          chargingStations: 'Estaciones de Carga',
          rangeMap: 'Mapa de Rango',
          routePlanner: 'Planeador de Ruta',
          dealerMap: 'Mapa del Concesionario',
        },
      },
      compare: {
        header: 'Comparar Vehículos Nuevos',
        subheader: 'Elija dos vehículos para comenzar la comparación.',
        smallScreenDisclaimer:
          'Para obtener más detalles, incluidas especificaciones y cálculos, vea esta página en el escritorio.',
        selectVehicle: {
          fuelType: 'Tipo de Combustible',
          carMake: 'Marca de Auto',
          carModel: 'Modelo de Auto',
        },
        pricePanel: {
          afterIncentives: 'Después de los Incentivos',
          msrp: 'MSRP',
          estimatedIncentives: 'Incentivos Estimados',
          availableIncentives: '{incentivesQuantity} Incentivos disponibles>',
        },
        specs: {
          carMake: 'Marca de Auto',
          carModel: 'Modelo de Auto',
          vehicleType: 'Tipo de Vehiculo',
          fuelType: 'Tipo de Combustible',
          batterySize: 'Tamaño de la Batería',
          totalRange: 'Rango Total',
          timeToChargeLevel2: 'Tiempo de Carga - Nivel 2',
          milesPer30MinFastCharge: 'Millas por 30 minutos de Carga Rápida',
          emissionsReduction: 'Reducción de Emisiones de CO2',
          viewDetails: 'Ver {vehicleDisplayName}',
          gasoline: 'Gasolina',
          electric: 'Eléctrico',
          electricAndGasoline: 'Electric y la gasolina',
        },
        filters: {
          purchaseMethod: 'Método de compra',
          annualMiles: 'Millas conducidas anualmente',
          phevElectricPortion: 'Porción eléctrica para PHEV',
          yearsOfOwnership: 'Años de propiedad/arrendamiento',
          interestRate: 'Tasa de interés',
          gasPrice: 'Precio de gasolina ($/gal)',
          includeResale: 'Incluir reventa de vehículos',
        },
        assumptions: {
          header: 'Suposiciones',
          electricityRate:
            'La tasa de electricidad se calcula como el promedio de seis meses de verano en horas fuera de pico y seis meses de invierno en horas fuera de pico para la tarifa {rateLink}.',
          equivalentVehicleDisclaimer:
            'Seleccionamos el {vehicle2} basado en su proximidad al {vehicle1} basado en la marca, el tamaño y el precio.',
          fastChargingSpeed: 'Velocidad de carga rápida',
          level2ChargingSpeed: 'Velocidad de carga de nivel 2',
          chargeRate: '{rate} kw',
          maxChargeRateDisclaimer: 'El {vehicle} solo puede cargar al máximo de {chargeRate} KW.',
          normalChargeRateDisclaimer: 'Se supone que el {vehicle} se cobra en {chargeRate} KW.',
          range: 'Rango basado en estimaciones de la EPA.',
          salesTax: 'Impuesto de Venta',
          downPayment: 'Depósito',
          downPaymentValue: '{percent}% de MSRP + impuestos',
          electricityRateLabel: 'Tasa de Electricidad',
        },
        charts: {
          header: 'Compare el {vehicle1Link} con el {vehicle2Link}',
          fuelCostComparisonExpensive:
            'El {vehicle} es {costDelta} más caro de llenar mensualmente',
          fuelCostComparisonCheap: 'El {vehicle} es {costDelta} más barato de llenar mensualmente',
          expensive: 'El {vehicle} es {costDelta} más caro de poseer más de {ownershipYears} años',
          cheap: 'El {vehicle} es {costDelta} más barato de poseer más de {ownershipYears} años',
          vehicleCost: 'Incentivos netos de vehículos',
          electricity: 'Electricidad',
          gasoline: 'Gasolina',
          maintenance: 'Mantenimiento',
          insurance: 'Seguro',
          tooltipVehicles:
            'Los vehículos eléctricos son actualmente más caros que los motores de combustión interna tradicionales. Sin embargo, parte de este costo inicial puede compensarse con incentivos de vehículos, menores costos de propiedad y un valor de reventa más fuerte',
          tooltipElectricity:
            'El costo de la electricidad es significativamente más barato que el de la gasolina.',
          tooltipGasoline:
            'La gasolina es un combustible más caro para su vehículo. Con el tiempo, este gasto puede volverse aún más pronunciado.',
          tooltipMaintenance:
            'Los costos de mantenimiento son significativamente más bajos para los vehículos eléctricos debido a las menos piezas móviles.',
          tooltipInsurance:
            'Con los costos más altos de los vehículos eléctricos, el seguro para el vehículo también es a menudo más alto.',
          paybackPeriodHeader:
            'Compare el costo acumulativo de por vida del {vehicle1} con un {vehicle2}',
          showGraphValues: 'Mostrar valores de gráficos',
          hideGraphValues: 'Ocultar valores de gráficos',
          cashPurchaseDisclaimer:
            'Nota: El cuadro de equilibrio supone que los vehículos se compran en efectivo',
          yearHeader: 'Año',
          year: 'Año {numYear}',
          fastChargingSameMiles:
            'El {vehicle1} y {vehicle2} cobran el mismo número de millas por 30 minutos de carga rápida',
          fastChargingMoreMiles:
            'El {vehicle} carga la mayor cantidad de millas por cada 30 minutos de carga rápida',
          level2ChargingSameRate:
            'El {vehicle1} y {vehicle2} se cargan a capacidad completa con un cargador de nivel 2 a la misma velocidad',
          level2ChargingFaster:
            'El {vehicle} se carga a plena capacidad más rápido con un cargador de nivel 2',
          sameRange: 'El {vehicle1} y {vehicle2} tienen el mismo rango',
          longerRange: 'El {vehicle} tiene el rango más largo',
          costChart: {
            selectionLabel: 'Su Selección de VE',
            similarLabel: 'Vehículo de Gas Similar',
            toggleCostBreakdown: 'Mostrar Desglose de Costos',
          },
        },
        table: {
          purchase: {
            title: 'Vehículo',
            purchaseMethod: 'Método de Compra',
            downPayment: 'Depósito',
            assumption: 'Asunción: {assumption}',
            loan: 'Monto del Préstamo',
            loanExplainer: 'MSRP * (1 + impuesto a las ventas) - pago inicial',
            monthlyPayments: 'Pagos Mensuales',
            monthlyPaymentsExplainer:
              'Función financiera del monto del préstamo, tasa de interés y meses de propiedad',
            monthsOfOwnership: 'Meses de Propiedad',
            totalMonthlyPayments: 'Pagos Mensuales Totales',
            totalMonthlyPaymentsExplainer: 'Pago mensual * meses de propiedad',
            salesTax: 'Impuesto de Venta',
            incentives: 'Incentivos',
            capitalizedIncentives: 'Incentivos Capitalizados',
            capitalizedIncentivesExplainer: 'Incentivos capitalizados dentro del arrendamiento',
            capitalizedCost: 'Costo Capitalizado',
            capitalizedCostExplainer: 'MSRP - pago inicial - Incentivos capitalizados',
            monthlyDepreciation: 'Costo de depreciación mensual',
            monthlyDepreciationExplainer:
              '(Costo capitalizado - valor de reventa) / meses de propiedad',
            moneyFactor: 'Factor de Dinero',
            moneyFactorExplainer: 'Equivalente a la entrada del usuario de {interestRate}',
            monthlyFinancing: 'Costo de financiamiento mensual',
            monthlyFinancingExplainer: '(Costo capitalizado + valor de reventa) * Factor de dinero',
            monthlyLease: 'Pago de Arrendamiento Mensual',
            monthlyLeaseExplainer:
              '(Costo de depreciación mensual + costo de financiación mensual) * (1 + impuesto sobre las ventas)',
            firstLease: 'Primer Pago de Arrendamiento',
            firstLeaseExplainer: 'Pago inicial + pago mensual de arrendamiento',
            nonCapitalizedLeaseIncentives: 'Incentivos de arrendamiento no capitizados',
            nonCapitalizedLeaseIncentivesExplainer:
              'Incentivos no capturados en el costo de arrendamiento capitalizado',
            resale: 'Valor de Reventa',
            resaleExplainer:
              'Se calcula como función del PVP del vehículo y {numMiles} millas de use por vida, asumiendo una depreciación exponencial negativa basada en el uso, donde el valor de reventa es del 60% después de 36,000 millas.',
            totalCost: 'Costo Total del Vehículo',
            totalCostExplainerCash:
              'Vehículo MSRP * (1 + impuesto a las ventas) - Incentivos - Valor de reventa',
            totalCostExplainerLoan:
              'Pago inicial + pagos mensuales totales - incentivos - valor de reventa',
            totalCostExplainerLease:
              'Primer pago de arrendamiento + (meses de propiedad - 1) * (pago mensual de arrendamiento) - Incentivos de arrendamiento no capitizados',
          },
          electricity: {
            title: 'Electricidad',
            rateExplainer:
              'Promedio durante seis meses de verano y seis meses de pico de pico de invierno para {rateLink}',
            efficiency: 'KWH del vehículo por cada 100 millas',
            efficiencyExplainer: 'Calificación de eficiencia de la EPA',
            costPer100Miles: '$/100 mi conducido',
            costPer100MilesExplainer:
              'Costo de electricidad * KWH del vehículo por cada 100 millas',
            totalElectricityCost: 'Costo total de electricidad',
            totalElectricityCostExplainer: '$ / 100 millas * millas de vida / 100',
            totalElectricityCostExplainerPHEV:
              '$ / 100 millas conducidas * millas de por vida * porción de conducción eléctrica / 100',
          },
          gasoline: {
            title: 'Gasolina',
            gasolineCost: 'Costo de Gasolina',
            mpgLabel: 'MPG del vehículo (millas por galón)',
            mpg: '{mpg} millas/chica',
            totalGasolineCost: 'Costo Total de Gasolina',
            totalGasolineCostExplainer: 'Costo de gasolina * millas de por vida / mpg',
            totalGasolineCostExplainerPHEV:
              'Costo de gasolina * millas de por vida * (1 - porción de conducción eléctrica) / mpg',
          },
          maintenance: {
            title: 'Mantenimiento',
            maintenancePerMileGas: 'Mantenimiento por milla para gasolina',
            maintenancePerMileGasExplainer:
              'Extrapolación del costo de mantenimiento promedio de {costPerMile}, impulsado por MSRP del vehículo en relación con MSRP promedio de {avgMsrp}',
            evCostReduction: 'Reducción de Costos de Mantenimiento de VE',
            consumerReports: 'Informes de los Consumidores',
            maintenancePerMileElec: 'Mantenimiento por milla para electricidad',
            maintenancePerMileElecExplainer:
              'Mantenimiento por milla para gasolina * EV Factor de reducción de mantenimiento',
            blendedMaintenancePerMile: 'Costo de mantenimiento mezclado por milla',
            blendedMaintenancePerMileExplainer:
              'Porción de conducción eléctrica aplicada al mantenimiento por milla para gasolina y para electricidad',
            totalMaintenanceCost: 'Costo de Mantenimiento Total',
            totalMaintenanceCostExplainer: 'Costo de mantenimiento por milla * millas de por vida',
          },
          insurance: {
            title: 'Seguro',
            avgInsurancePerYear: 'Seguro Promedio por Año',
            insurancePerYear: 'Seguro por Año',
            insurancePerYearExplainer:
              'Extrapolación del costo promedio de seguro en California, conducido por MSRP de vehículos en relación con MSRP promedio de {avgMsrp}',
            yearsOfOwnership: 'Años de Propiedad',
            totalInsuranceCost: 'Costo Total de Seguro',
            totalInsuranceCostExplainer: 'Costo de seguro por año * Años de propiedad',
            avgInsuranceCostText: 'Costo Promedio de Seguro en California',
          },
          description: 'Descripción',
          source: 'Fuente',
          userInput: 'Entrada del usuario',
          msrp: 'PVP del Vehículo',
          lifetimeMiles: 'Millas de por vida',
          phevPortion: 'Parte de la conducción eléctrica',
          phevPortionExplainer:
            'Entrada del usuario. Para un vehículo híbrido, estima la porción de millas conducidas con la batería eléctrica en lugar de la gasolina.',
          showSources: 'Mostrar fuentes',
          hideSources: 'Ocultar fuentes',
          ariaLabel: 'Fuentes de cálculo',
          salesTaxSourceText: 'Tasa impositiva de ventas y uso en todo el estado',
        },
        alternativeVehicle: 'Seleccione el Vehículo de Comparación',
      },
      incentives: {
        headline: 'Incentivos de vehículos eléctricos',
        description:
          'Puede ser elegible para una variedad de incentivos, incluidos reembolsos de EV, créditos fiscales de EV y varios otros beneficios. Los incentivos son personalizados para donde vive.',
        selectVehicleError:
          'Seleccione una marca y modelo de vehículo en el panel izquierdo para ver incentivos.',
        selectVehicleErrorSmallScreen:
          'Seleccione una marca y modelo de vehículo en el cuadro de diálogo Filtros para ver incentivos.',
        zipcodeError: 'Por favor ingrese un código postal válido.',
        eligibleHeadline:
          'Eres elegible para un total de {eligibleAmount} en reembolsos y créditos fiscales',
        maybeHeadline: 'Puede ser elegible para',
        ineligibleHeadline: 'Basado en sus aportes, no es elegible para',
        filters: 'Filtros',
        eligibilityFilters: {
          headline: 'Filtros de Elegibilidad',
          zipcode: 'Código Postal',
          provider: 'Proveedor de Electricidad',
          income: 'Ingresos del Hogar',
          sizeOfHousehold: 'Tamaño del Hogar',
          taxFilingStatus: 'Estado de presentación de impuestos',
          retiringVehicle: 'Retirando un Vehículo',
          taxStatuses: {
            single: 'Único',
            joint: 'Casado',
            headOfHousehold: 'Jefe de Hogar',
          },
        },
        vehicleFilters: {
          headline: 'Filtros de Vehículos Eléctricos',
          make: 'Marca',
          model: 'Modelo',
        },
      },
      maps: {
        header: 'Localizar Estaciones de Carga',
        stations: 'Estaciones de Carga',
        range: 'Mapa de Rango',
        route: 'Planeador de Ruta',
        dealer: 'Mapa del Concesionario',
        availability: 'Disponibilidad de Estaciones',
        psps: 'Puede estar sucediendo un evento Cortes de Energía de Seguridad Pública (PSPS, siglas en ingles). Consulte el {link} para verificar.',
        pspsLink:
          'Página oficial de Cortes de Energía de Seguridad Pública (PSPS, siglas en ingles)',
        pspsBanner:
          'Caída de energía de seguridad pública en efecto. Consulte la página oficial de Cortes de Energía de Seguridad Pública (PSPS, siglas en ingles) {link}.',
        pspsBannerError:
          'Puede estar sucediendo un evento PSPS. Consulte el {link} para verificar.',
        pspsBannerLink: 'aquí',
        pspsBannerErrorLink:
          'Página oficial de Cortes de Energía de Seguridad Pública (PSPS, siglas en ingles)',
        pspsEventArea:
          'Área de eventos de Cortes de Energía de Seguridad Pública (PSPS, siglas en ingles)',
        chargingStationNetworkUnavailable: 'Enlace a la red no disponible',
        dealerMap: {
          showButton: 'Lista completa de concesionarios',
          hideButton: 'Ocultar la lista de concesionarios',
          contact: 'Contacto',
          website: 'Sitio web',
        },
        stationsMap: {
          showButton: 'Lista completa de estaciones',
          hideButton: 'Ocultar la lista de estaciones',
          publicStationTitle: 'Centrales Eléctricas',
          publicStationDescription: 'Cargadores de Nivel 2',
          dcfcStationTitle: 'Estaciones de Alta Potencia',
          dcfcStationDescription: 'Cargadores rápidos de CC',
          privateStationTitle: 'Otras Estaciones',
          privateStationDescription: 'Estaciones privadas',
        },
        availabilityMap: {
          searchChargingStations: 'Estaciones de carga de búsqueda',
          availabilityStationTitle: 'Disponibilidad y compatibilidad con enchufe',
          availabilityStationDescription: 'Tiene datos de tipo de enchufe y disponibilidad.',
          plugOnlyStationTitle: 'Compatibilidad de Enchufe',
          plugOnlyStationDescription: 'Tiene datos de tipo de enchufe.',
          consent: 'Al usar este servicio, usted acepta estos ',
          consentLink: 'términos de uso',
        },
        travelRadiusMap: {
          viewTravelRadius: 'Ver Radio de Viaje',
          fullBatteryRange: '{currentElectricRange} milla de rango eléctrico, batería completa',
          fullGasRange: '{currentTotalRange} Mile Rango total, tanque completo de gas',
        },
        dealersMap: {
          searchQualifiedDealers: 'Buscar Distribuidores Calificados',
          brands: 'Marcas',
          dealers: 'Distribuidores',
          allBrands: 'Todas Marcas',
          allDealers: 'Todos Distribuidores',
        },
      },
      news: {
        header: 'Noticias',
        readMore: 'Leer Más →',
        error: 'Lo sentimos, hubo un problema al cargar las noticias.',
      },
      feedback: {
        title: 'Comentarios',
        whatIsGoal: '¿Cuál es su objetivo de visitar ev.pge.com?',
        didYouAchieveGoal: '¿Logró el objetivo de tu visita?',
        yes: 'Sí',
        no: 'No',
        howWouldYouImprove: '¿Cómo mejoraría esta herramienta?',
        submit: 'Enviar',
      },
      error: {
        header: 'Error',
      },
      rateInfo: esTranslationRateInfo,
      common: {
        chargingPeriods: {
          afterMidnightBefore3pm: 'Después de medianoche; Antes de las 3 p.m.',
          after9pmBefore4pm: 'Antes de las 4 p.m. o después de las 9 p.m.',
          after4pmBefore9pm: 'Después de las 4pm; Antes de las 9 p.m.',
          all: 'Todas las Horas',
          unpredictable: 'Todas Horas (Impredecible)',
        },
        purchaseMethods: {
          cash: 'Dinero',
          loan: 'Préstamo',
          lease: 'Arrendar',
        },
        zipInput: {
          label: 'Código Postal',
          placeholder: 'Introduzca Código Postal',
          button: 'Actualizar Código Postal',
          error: 'Código postal no válido - ingrese 5 dígitos',
        },
        address: 'Dirección',
        default: 'Valor por defecto',
        miles: 'Millas',
        submit: 'Enviar',
        submitting: 'Enviando...',
        vehicle: 'Vehículo',
        years: 'Años',
      },
      chargerDetails: {
        backToMap: 'Enchufes y Disponibilidad de Estaciones',
        routeToChargerButton: 'Ruta al Cargador',
        chargerWebsiteButton: 'Sitio Web del Cargador',
        routeToChargerHeadline: 'Ruta al Cargador',
        estimatedChargingCost: {
          headline: 'Estimador de Costos de Carga',
          makeLabel: 'Marca del Vehículo',
          modelLabel: 'Modelo de Vehículo',
          costPerKwhLabel: '$/kwh',
          result: 'Carga Completa',
        },
        routeMap: {
          startLocationLabel: 'Ubicación Inicial',
          endLocationLabel: 'Ubicación Final',
          mapRouteButton: 'Ruta',
          routeDistance: 'Esta ruta mide {distanceInMiles} millas',
          stationFetchError: 'Hubo un error al buscar estaciones de carga. Inténtelo de nuevo.',
          routeCreateError:
            'No pudimos encontrar esa ruta. Consulte las direcciones y vuelva a intentarlo.',
          highPoweredStationsOnly: 'Solo Estaciones de Alta Potencia',
        },
      },
      npsScore: {
        prompt: '¿Recomendaría esta pagina?',
        notLikely: 'No es Probable',
        veryLikely: 'Muy Probable',
        selectResponse: 'Seleccione su respuesta',
      },
      search: {
        searchInputLabel: 'Buscar',
        searchInputPlaceholder: 'Buscar vehículos, incentivos, noticias…',
        filterTitle: 'Filtrar Resultados',
        resultsCount: '{count} resultados',
        viewMore: 'Ver más',
        viewLess: 'Ver menos',
        vehiclesTitle: 'Vehículos',
        noMatchingVehicles: 'No existen vehiculos coincidentes',
        incentivesTitle: 'Incentivos',
        noMatchingIncentives: 'No existen incentivos coincidentes',
        articlesTitle: 'Noticias',
        noMatchingArticles: 'No existen artículos de noticias coincidentes',
      },
    },
  },
  zh: {
    translation: {
      languageName: '中文',
      errorBoundary: {
        header: '请重制您的输入',
        body: '您要么已经修改了一个有效的网址，或者我们更新了车辆数据，作为我们与时俱进努力的一部分。请使用下面的按钮重制您的输入以解决问题。',
        resetButton: '重启',
      },
      nav: {
        home: '主页',
        rates: '电动车费率',
        vehicles: '汽车',
        compare: '比较',
        incentives: '奖励计划',
        maps: '地图',
        stations: '充电站',
        availability: '可使用充电站',
        route: '路线规划',
        range: '范围地图',
        dealer: '经销商分布图',
        faq: '常见问题',
        chargingFaq: '充电',
        news: '新闻',
        privacy: '隐私权政策',
        cookies: 'Cookie 设定',
        poweredBy: '由 © J.D. Power ZappyRide 提供',
        feedback: '请提供您宝贵的反馈，以帮助改进电动车节能计划',
        copyright: '©{year} Pacific Gas and Electric Company',
        search: '搜索',
      },
      homepage: {
        intro: {
          headline: '欢迎使用电动车节能计划',
          details: '您的电动汽车指南。为您估算和比较成本、节省、提供电车奖励计划等。',
          browse: '浏览电动车',
          discover: '了解能源奖励计划',
          compare: '对比费率计划',
          locate: '寻找充电站',
        },
        whyElectric: {
          headline: '为什么电动汽车是个好主意。',
          saveMoney: '省钱',
          saveMoneyDetails: '与汽油车相比，电动车的燃料和拥有成本更低',
          hassle: '少麻烦',
          hassleDetails: '电动车无需定期维护 – 无需更换机油，过滤器或发动机皮带',
          driveAnywhere: '随时随地开车',
          driveAnywhereDetails: '不断改进的电池和充电设施提供了自由和安心',
        },
        evCosts: {
          vehicle: '汽车',
          maintenance: '维护',
          insurance: '保险',
          electricity: '电源',
          gasoline: '汽油',
          total: '总共',
          showTableButton: '显示图值表',
          hideTableButton: '隐藏图值表',
          seeEvButton: '了解电动车',
          seeAllEvsButton: '查看所有车辆',
          availableEvs: '现有{vehicleCount}可使用电动汽车。寻找专属您的那辆。',
          compareCosts: '用我们的计算器比较一下类似的汽油车的平均成本。',
          findEvButton: '寻找适合您需求的电动车',
        },
        rateCompare: {
          headline: '您知道我们提供各种电费套餐吗？找到适合您的电车费率。',
          description: '该工具将为您找到最低的电车费率计划，并帮您在线注册费率计划。',
          startButton: '开始',
          formDetails: '不到一分钟的个性化费率。\n无需注册。',
        },
        incentives: {
          headline: '探索潜在的电车奖励计划和税收抵免',
          details:
            '无论您是购买还是租凭，看看电动车能为您能节省多少钱。奖励计划将根据您的居住地进行个性化的设置。',
          personalizeButton: '个性化能源奖励计划',
        },
        news: {
          headline: '新闻',
          description: 'PG&E 的最新资讯',
          readMoreLink: '了解更多',
        },
        affordableEv: {
          quote: '在现有的平价电动车中，充满电的电池足以满足全美87%的司机一整天的出行需求。',
          source: 'Nature International Journal of Science (国际科学与自然杂志)',
          button: '探索电动汽车',
        },
      },
      rates: {
        header: '为您的电动车找到最低成本的电费。',
        description: '在下方调整您的信息，找到适合您的电价。',
        filtersCta: '个性化结果',
        filters: {
          basicFilters: {
            title: '基本筛选条件',
            currentRateLabel: '当前费率',
            evMakeLabel: '电车品牌',
            evModelLabel: '电车型号',
            milesPerYearLabel: '每年行驶里程',
            miles: '里程',
            chargingPatternLabel: '充电模式',
          },
          homeAssumptions: {
            title: '模拟家庭',
            monthlyElectricBillLabel: '家庭每月电费单',
            perMonth: ' / 月',
            solarAtHomeLabel: '你家有太阳能吗？',
            solarAtHomeDetails: '如果安装了太阳能，电源负荷情况会发生很大的变化。',
          },
          drivingAssumptions: {
            title: '模拟驾驶',
            phevPortionLabel: '电动里程的比例',
            gasPriceLabel: '汽油价格',
            fossilEfficiencyLabel: '常规汽车效率',
            publicChargingPortionLabel: '公共充电的比例',
            publicChargingCostLabel: '公共充电费用',
            optionFree: '免费',
            optionLow: '低：$0.20美元/千瓦时',
            optionMedium: '中等：$0.40美元/千瓦时',
            optionHigh: '高：$0.60美元/千瓦时',
          },
          phevPortion: '{elec}% 电动/ {gas}% 汽油',
          gasCost: '{val} / 加仑',
          mpg: '英里/加仑',
          yes: '是',
          no: '否',
        },
        chart: {
          title: '价格对比',
          headerSavings:
            '如果您开的是一辆{vehicle} 并切换到PG&E的{rate}费率，您每年将可节省 {amount}美元。',
          headerExtraSpend:
            '如果您开的是一辆{vehicle} 并切换到PG&E的{rate}费率，您将每年额外花费 {amount} 美元。',
          noVehicleMessage: '请选择车辆。',
          costLabel: '每年的大约平均费用',
          gasolineToggle: '汽油',
          homeToggle: '主页',
          rateChangeButton: '更改您的费率',
          homeElec: '家庭用电',
          ev: '电动汽车',
          gas: '汽油',
          currentRate: '目前电费：{rate}',
          lowestRate: '最低电费：{rate}',
          withoutEv: '没有电动车',
          withEv: '有电动车',
        },
        allRates: {
          title: '所有电费套餐',
          noVehicleMessage: '请选择一辆汽车',
          costLabel: '每年的大约平均费用',
          savings: '节省 {dollars}',
        },
        wizard: {
          next: '下一页',
          previous: '上一页',
          seeResults: '查看结果',
          stepProgress: '{totalSteps} 中的第{currentStep} ',
          monthlyBill: {
            monthlyElectricBillTitle: '您的平均家庭电费是多少？',
            monthlyElectricBillLabel: '家庭每月电费单',
            monthlyElectricBillSuffix: ' / 月',
            dollarSign: '$',
          },
          vehicle: {
            vehicleTitle: '车辆',
            vehicleMakeLabel: '电车品牌',
            vehicleModelLabel: '电车型号',
            vehicleHelperLink: '帮我选择',
          },
          annualMiles: {
            milesPerYearTitle: '你平均每年开多少英里？',
            milesPerYearHelper: '提示：一位普通加州居民每年开车约12,000英里。',
            milesPerYearLabel: '每年行驶里程数',
            milesPerYearSuffix: '英里/加仑',
          },
          chargingTime: {
            chargingTimeTitle: '哪个充电时间段最适合你？',
            chargingTimeHelper: '提示：晚上充电效率最高。',
            chargingTimeMidnight: '午夜 ',
            chargingTimeAm: 'am (上午）',
            chargingTimePm: 'pm (晚上) ',
          },
          rate: {
            electricRateTitle: '您目前的电费率是多少？',
            electricRateHelper: '提示：大部分用户都在用E-TOU-C',
          },
          summary: {
            savings:
              '如果您开的是一辆{vehicle} 并切换到PG&E的{rate}费率，您每年将可节省 {amount}美元。',
            extraSpend:
              '如果您开的是一辆{vehicle} 并切换到PG&E的{rate}费率，您将每年额外花费 {amount} 美元。',
          },
        },
      },
      vehicleCatalog: {
        header: '比较电动汽车',
        description:
          '根据电动车的续航里程、价格或您的个性化匹配分数来比较电动汽车。请点击以下电动车了解更多资讯，包括与类似汽油车相比的总成本。',
        sortBy: '排序方式',
        showMore: '显示更多',
        numVehiclesShown: '{total} 中的 {shown} ',
        filters: '筛选',
        sortOptions: {
          range: '电动续航距离',
          alphabetical: '按字母排序',
          priceLow: '价格：低至高',
          priceHigh: '价格：高至低',
        },
        filterControls: {
          title: '筛选结果',
          range: '范围',
          miles: '英里',
          budget: '奖励计划后的预算',
          personalizeIncentivesButton: '个性化奖励计划',
          seatsMin: '最低座位数量',
          seats: '座位',
          homeChargerType: '家庭充电可用性',
          charging: {
            none: '不可充电',
            level1: '第1级别',
            level2: '第2级别',
          },
          chargerHelpButton: '帮我选择',
          moneySymbol: '$',
          fuel: '燃料',
          allElectric: '全电动',
          hybrid: '混合电动车',
          type: '类型',
          typeOptions: {
            sedan: '轿车',
            hatchback: '掀背车',
            coupe: '跑车',
            suv: 'SUV/越野车',
            minivan: '小型货车',
            wagon: '旅行车',
            truck: '卡车',
          },
          status: '车辆状况',
          upcoming: '即将推出',
          disclaimer:
            '所显示的车辆可能不能反映实时供应情况。我们并没有跟任何车辆供应商合作或推荐特定车辆型号。',
        },
        incentivesModal: {
          headline: '个性化奖励计划',
          location: '位置',
          zipCodeLabel: '邮政编码',
          taxFilingLabel: '报税身份',
          statusOptions: {
            single: '单身',
            married: '已婚',
            headOfHousehold: '户主',
          },
          householdSize: '家庭人数',
          householdIncome: '家庭收入',
          updateButton: '更新奖励计划資格',
        },
        vehicleCard: {
          evRange: '电动续航距离',
          totalRange: '总续航里程',
          msrp: '建议零售价',
          rangeMiles: '{numMiles} 英里',
          afterIncentives: '参加奖励计划后的价格',
          matchScore: '匹配分数',
        },
      },
      vehicleDetails: {
        notFound: '找不到车辆',
        header: {
          allVehicles: '所有车辆',
          afterIncentives: '参加奖励计划后的价格',
          loanPayment: '贷款金额',
          downPayment: '首付金额',
          leasePayment: '租凭支付',
          perMonth: '{val} / 月',
          firstLeasePayment: '首笔租凭付款',
          incentivesForLease: '租赁奖励',
          msrp: '建议零售价',
          estimatedIncentives: '估计奖励',
          allCarsLink: '浏览所有汽车',
          findDealersLink: '寻找经销商',
        },
        specs: {
          type: '类型',
          fuelType: '燃料类型',
          milesPerThirtyCharge: '每30分钟快速充电里程数',
          batterySize: '电池规格',
          treesPlanted: '植树数量',
          trees: '{val} 棵树',
          zeroToSixty: '0-60英里/小时加速',
          seats: '座位',
          seatsRange: '{low} 至 {high}',
          range: '电动续航距离',
          chargeTimeL2: '充电时间 - 第2级别',
          co2Reduction: '二氧化碳减排',
          gasSaved: '节省汽油',
          drivetrain: '动力传递装置',
          kwh: '{val} 千瓦时',
          seconds: '{val} 秒',
          miles: '{val} 英里',
          estMiles: '~ {val} 英里',
          lbsPerYr: '{val} 磅/年',
          galPerYr: '{val} 加仑/年',
          fuelTypes: {
            gasoline: '汽油',
            electric: '电动',
            electricAndGasoline: '电力和汽油',
          },
          drivetrainTypes: {
            '4wheelDrive': '四轮驱动',
            rearwheelDrive: '后轮驱动',
            allwheelDrive: '全轮驱动',
            frontwheelDrive: '前轮驱动',
          },
        },
        costsSmallScreenDisclaimer: '更多有关规格和报价细节，请在桌面上查看此页面。',
        incentivesHeader: '{vehicle} 有可能获得高达 {amount}的税收抵免和退税',
        maps: {
          chargingStations: '充电站',
          rangeMap: '范围图',
          routePlanner: '路线规划',
          dealerMap: '经销商分布图',
        },
      },
      compare: {
        header: '新车比较',
        subheader: '请选择两辆车进行对比',
        smallScreenDisclaimer: '更多有关规格和报价细节，请在桌面上查看此页面。',
        selectVehicle: {
          fuelType: '燃料类型',
          carMake: '汽车品牌',
          carModel: '汽车型号',
        },
        pricePanel: {
          afterIncentives: '参加奖励计划后的价格',
          msrp: '建议零售价',
          estimatedIncentives: '估计奖励',
          availableIncentives: '{incentivesQuantity} 可用奖励 >',
        },
        specs: {
          carMake: '汽车品牌',
          carModel: '汽车型号',
          vehicleType: '汽车类型',
          fuelType: '燃料类型',
          batterySize: '电池规格',
          totalRange: '总续航里程',
          timeToChargeLevel2: '充电时间 - 第2级别',
          milesPer30MinFastCharge: '每30分钟快速充电里程数',
          emissionsReduction: '二氧化碳减排',
          viewDetails: '查看 {vehicleDisplayName}',
          gasoline: '汽油',
          electric: '电动',
          electricAndGasoline: '电力和汽油',
        },
        filters: {
          purchaseMethod: ' 购买方式',
          annualMiles: '每年行驶里程数',
          phevElectricPortion: 'PHEV （插电式混合电动车) 的电动比例',
          yearsOfOwnership: '所有权/租凭年限',
          interestRate: '利率',
          gasPrice: '汽油价格（美元/加仑）',
          includeResale: '包括车辆转售',
        },
        assumptions: {
          header: '假设',
          electricityRate: '电费是按{rateLink} 夏季非高峰6个月和冬季非高峰6个月的平均值计算。',
          equivalentVehicleDisclaimer:
            '我们根据品牌、尺寸和价格与{vehicle1} 的接近程度选择了{vehicle2}。',
          fastChargingSpeed: '快速充电速度',
          level2ChargingSpeed: '2级充电速度',
          chargeRate: '{rate} 千瓦',
          maxChargeRateDisclaimer: '该{vehicle}最多只能以{chargeRate}千瓦充电。',
          normalChargeRateDisclaimer: '该{vehicle}预计将以{chargeRate}功率进行充电。',
          range: '范围基于EPA (环保署) 的估计。',
          salesTax: '销售税',
          downPaymentValue: '建议零售价的{percent}% + 税',
          electricityRateLabel: '电费',
          downPayment: '首付',
        },
        charts: {
          header: '对比{vehicle1Link} 和 {vehicle2Link}',
          fuelCostComparisonExpensive: '{vehicle}每月加满油的额外费用是{costDelta}',
          fuelCostComparisonCheap: '{vehicle}每月加满油能比平时节省{costDelta}',
          expensive: '{vehicle}在{ownershipYears}年内的所有权成本要高{costDelta}。',
          cheap: '{vehicle}在{ownershipYears}年内的所有权成本要便宜{costDelta}。',
          vehicleCost: '车辆奖励总金额',
          electricity: '电动',
          gasoline: '汽油',
          maintenance: '维修',
          insurance: '保险',
          tooltipVehicles:
            '电动车目前比传统的汽油车更贵。然而部分前期成本可以通过购车奖励计划，更低所有权成本和更高的转售价值来抵消差价。',
          tooltipElectricity: '电费要比汽油成本便宜多了。',
          tooltipGasoline: '汽油对于您的车辆来说是较昂贵的燃料。长远来看，这笔开销会更明显。',
          tooltipMaintenance: '由于移动零件较少，电动车的维护成本要低一些。',
          tooltipInsurance: '由于电动车的成本较高，保险往往也稍微要高一些。',
          paybackPeriodHeader: '比较{vehicle1}和{vehicle2}的生命周期成本',
          showGraphValues: '显示图值',
          hideGraphValues: '隐藏图值',
          cashPurchaseDisclaimer: '注:盈亏平衡图是假设车辆以现金购买',
          yearHeader: '年',
          year: '第{numYear}年',
          fastChargingSameMiles: '{vehicle1} 和 {vehicle2} 快速充电30分钟的里程数是一样的',
          fastChargingMoreMiles: '每30分钟快速充电，{vehicle} 的充电里程数最多',
          level2ChargingSameRate: '{vehicle1} 和 {vehicle2} 用二极充电器以相同的速率充满电',
          level2ChargingFaster: '{vehicle} 使用2级充电器能够更快地充满电',
          sameRange: '{vehicle1} 和 {vehicle2} 的续航里程一样',
          longerRange: '{vehicle} 有最长的续航里程',
          costChart: {
            selectionLabel: '您的电动车选择',
            similarLabel: '类似的汽油车',
            toggleCostBreakdown: '显示费用明细',
          },
        },
        table: {
          purchase: {
            title: '车辆',
            purchaseMethod: '购买方式',
            downPayment: '首付',
            assumption: '假设：{assumption}',
            loan: '贷款额度',
            loanExplainer: '建议零售价 * (1 + 销售税) - 首付',
            monthlyPayments: '月供',
            monthlyPaymentsExplainer: '贷款金额、利率和所有权时长的理财功能',
            monthsOfOwnership: '所有权月数',
            totalMonthlyPayments: '每月总付款',
            totalMonthlyPaymentsExplainer: '每月总付款 * 所有权月数',
            salesTax: '销售税',
            incentives: '奖励计划 ',
            capitalizedIncentives: '实现奖励',
            capitalizedIncentivesExplainer: '租车奖励计划',
            capitalizedCost: '资本化成本',
            capitalizedCostExplainer: '建议零售价 - 首付 - 实现奖励',
            monthlyDepreciation: '每月折旧成本',
            monthlyDepreciationExplainer: '（资本化成本 - 转售价值）- 所有权月数',
            moneyFactor: '租车利息',
            moneyFactorExplainer: '相当于用户输入的{interestRate}',
            monthlyFinancing: '每月融资成本',
            monthlyFinancingExplainer: '（资本化成本 - 转售价值）+ 租车利息',
            monthlyLease: '月租费用',
            monthlyLeaseExplainer: '（每月折旧成本 + 每月融资成本）* （1 + 销售税）',
            firstLease: '首笔租凭付款',
            firstLeaseExplainer: '首付 + 首笔租凭付款',
            nonCapitalizedLeaseIncentives: '非资本化租车奖励计划',
            nonCapitalizedLeaseIncentivesExplainer: '未包含在资本化租金的奖励计划',
            resale: '转售价值',
            resaleExplainer:
              '车辆零售价和{numMiles}的总里程数的公式，假设随着行驶里程的增加，负指数降值，36,000 英里后转售价为60%。',
            totalCost: '车辆总成本',
            totalCostExplainerCash: '车辆零售价 * （1 + 销售税）- 奖励 + 转售价值',
            totalCostExplainerLoan: '首付 + 总月供 - 奖励 - 转售价值',
            totalCostExplainerLease:
              '首笔租凭付款 + （所有权月数 - 1）* （月租费用）- 非资本化租车奖励计划',
          },
          electricity: {
            title: '电力',
            rateExplainer: '{rateLink} 夏季非高峰期6个月和冬季非高峰期6个月的平均值',
            efficiency: '车辆每100英里的千瓦时',
            efficiencyExplainer: 'EPA (环保署) 效率等级',
            costPer100Miles: '美元/100英里行驶',
            costPer100MilesExplainer: '电费 * 每行驶100英里的千瓦时',
            totalElectricityCost: '总电费',
            totalElectricityCostExplainer: '美元/每行驶100英里 * 总里程数 / 100',
            totalElectricityCostExplainerPHEV: '美元/每行驶100英里 * 总里程数 * 电动行驶比例 / 100',
          },
          gasoline: {
            title: '汽油',
            gasolineCost: '汽油费',
            mpgLabel: '车辆的MPG (英里/加仑)',
            mpg: '{mpg} 英里/加仑',
            totalGasolineCost: '总汽油费用',
            totalGasolineCostExplainer: '汽油费 * 总里程数 / MPG (英里/加仑)',
            totalGasolineCostExplainerPHEV: '汽油费 * 总里程数 ',
          },
          maintenance: {
            title: '维护',
            maintenancePerMileGas: '汽油车每英里的维护费用',
            maintenancePerMileGasExplainer:
              '{costPerMile}的平均维护费用是由车辆建议零售价和{avgMsrp}的均价而定的。',
            evCostReduction: '电动车的维护成本降低',
            consumerReports: '消费者报告',
            maintenancePerMileElec: '电动车每英里的维护费用',
            maintenancePerMileElecExplainer: '汽油车每英里的维护成本 * 电动车维护成本降低因素',
            blendedMaintenancePerMile: '每英里总维护费用',
            blendedMaintenancePerMileExplainer: '电动驾驶部分适用于汽油和电力的每英里维护',
            totalMaintenanceCost: '总维护费用',
            totalMaintenanceCostExplainer: '每英里维护费用 * 总里程数',
          },
          insurance: {
            title: '保险',
            avgInsurancePerYear: '每年平均保险费用',
            insurancePerYear: '每年保险',
            insurancePerYearExplainer:
              '加州的平均保险费用是由车辆建议零售价和{avgMsrp}的均价而定的。',
            yearsOfOwnership: '所有权年限',
            totalInsuranceCost: '总保险费用',
            totalInsuranceCostExplainer: '每年保险 * 所有权年限',
            avgInsuranceCostText: '加州的平均保险费用',
          },
          description: '说明',
          source: '来源',
          userInput: '用户输入',
          msrp: '车辆零售价',
          lifetimeMiles: '总里程数',
          phevPortion: '电动行驶比例',
          phevPortionExplainer:
            '用户输入。对于混合动力汽车，估计使用电池而不是汽油的行驶里程的比例。',
          showSources: '显示来源',
          hideSources: '隐藏来源',
          ariaLabel: '计算来源',
          salesTaxSourceText: '全州基本销售和使用税率',
        },
        alternativeVehicle: '请选择对比车型',
      },
      incentives: {
        headline: '电动车奖励计划',
        description:
          '您可能有资格获得一系列的奖励计划，其中包括电动车退税，电动车税收抵免，以及其他各种福利。奖励计划是根据您居住的地区进行个性化定制。',
        selectVehicleError: '请在左侧面板选择车辆品牌和型号以查看奖励。',
        selectVehicleErrorSmallScreen: '请在筛选对话框中选择车辆品牌和型号以查看奖励。',
        zipcodeError: '请输入您的邮政编码。',
        eligibleHeadline: '您有资格获得总额{eligibleAmount}的退税和税收抵免',
        maybeHeadline: '您可能有资格获得',
        ineligibleHeadline: '根据您的输入，您不符合以下计划',
        filters: '筛选',
        eligibilityFilters: {
          headline: '资格筛选',
          zipcode: '邮政编码',
          provider: '电力供应商',
          income: '家庭收入',
          sizeOfHousehold: '家庭人数',
          taxFilingStatus: '报税身份',
          retiringVehicle: '车辆报废',
          taxStatuses: {
            single: '单身',
            joint: '已婚',
            headOfHousehold: '户主',
          },
        },
        vehicleFilters: {
          headline: '电动车筛选',
          make: '汽车品牌',
          model: '汽车型号',
        },
      },
      maps: {
        header: '寻找电动车充电站',
        stations: '充电站',
        range: '范围地图',
        route: '路线规划',
        dealer: '经销商分布图',
        availability: '可用充电站',
        psps: 'PSPS (公共安全断电)事件可能正在发生。请查看{link}确认。',
        pspsLink: 'PSPS (公共安全断电)官网',
        pspsBanner: '目前正在断电。请查看PSPS (公共安全断电)官网 {link}。',
        pspsBannerError: 'PSPS (公共安全断电)事件可能正在发生。请查看{link}确认。',
        pspsBannerLink: '这里',
        pspsBannerErrorLink: 'PSPS (公共安全断电)官网',
        pspsEventArea: 'PSPS (公共安全断电) 断电分布区',
        chargingStationNetworkUnavailable: '网络连接故障',
        dealerMap: {
          showButton: '全部经销商列表',
          hideButton: '隐藏经销商列表',
          contact: '联系',
          website: '网址',
        },
        stationsMap: {
          showButton: '全部充电站列表',
          hideButton: '隐藏充电站列表',
          publicStationTitle: '发电站',
          publicStationDescription: '2级充电器',
          dcfcStationTitle: '高功率充电站',
          dcfcStationDescription: '直流快速充电器',
          privateStationTitle: '其他充电站',
          privateStationDescription: '私人充电站',
        },
        availabilityMap: {
          searchChargingStations: '搜索充电站',
          availabilityStationTitle: '可用充电站和兼容性',
          availabilityStationDescription: '有插头类型和可用性数据',
          plugOnlyStationTitle: '充电插头兼容性',
          plugOnlyStationDescription: '有插头类型数据',
          consent: '通过使用此服务，您同意以下条款',
          consentLink: '使用条款',
        },
        travelRadiusMap: {
          viewTravelRadius: '查看行驶半径',
          fullBatteryRange: '{currentElectricRange} 英里电力范围，充满电',
          fullGasRange: '{currentTotalRange} 英里总航程，满油箱',
        },
        dealersMap: {
          searchQualifiedDealers: '搜索符合条件的经销商',
          brands: '品牌',
          dealers: '经销商',
          allBrands: '所有品牌',
          allDealers: '所有经销商',
        },
      },
      news: {
        header: '新闻',
        readMore: '了解更多',
        error: '抱歉，加载新闻页面时出现了问题。',
      },
      feedback: {
        title: '反馈',
        whatIsGoal: '您访问 ev.pge.com 的目的是什么？',
        didYouAchieveGoal: '您达到访问的目的了吗？',
        yes: '是',
        no: '否',
        howWouldYouImprove: '您会如何改进这个工具？',
        submit: '提交',
      },
      error: {
        header: '错误',
      },
      rateInfo: zhTranslationRateInfo,
      common: {
        chargingPeriods: {
          afterMidnightBefore3pm: '午夜后；下午3点前',
          after9pmBefore4pm: '下午4点前或晚上9点后',
          after4pmBefore9pm: '下午4点至9点',
          all: '全天候',
          unpredictable: '所有时间（不可预测）',
        },
        purchaseMethods: {
          cash: '现金',
          loan: '贷款',
          lease: '租凭',
        },
        zipInput: {
          label: '邮政编码',
          placeholder: '输入邮政编码',
          button: '更新邮政编码',
          error: '错误邮政编码，应为5位数',
        },
        address: '地址',
        default: '默认',
        miles: '英里',
        submit: '提交',
        submitting: '正在提交中...',
        vehicle: '车辆',
        years: '年份',
      },
      chargerDetails: {
        backToMap: '充电插头及可用性',
        routeToChargerButton: '前往充电站的路线',
        chargerWebsiteButton: '充电站官网',
        routeToChargerHeadline: '前往充电站的路线',
        estimatedChargingCost: {
          headline: '充电成本估计',
          makeLabel: '电车品牌',
          modelLabel: '电车型号',
          costPerKwhLabel: '美元/千瓦时',
          result: '充满电',
        },
        routeMap: {
          startLocationLabel: '出发地点',
          endLocationLabel: '终点',
          mapRouteButton: '路线图',
          routeDistance: '这是一条{distanceInMiles}英里的路线。',
          stationFetchError: '搜索充电站信息时出现了网络错误。请稍后再试。',
          routeCreateError: '我们找不到该路线，请确认地址并重试。',
          highPoweredStationsOnly: '仅限高功率充电站',
        },
      },
      npsScore: {
        prompt: '您会推荐这个工具吗？',
        notLikely: '不太可能',
        veryLikely: '很可能',
        selectResponse: '请选择您的回复',
      },
      search: {
        searchInputLabel: '搜索',
        searchInputPlaceholder: '搜索车辆、激励计划，新闻等等',
        filterTitle: '筛选结果',
        resultsCount: '{count} 结果',
        viewMore: '查看更多',
        viewLess: '查看更少',
        vehiclesTitle: '车辆',
        noMatchingVehicles: '无匹配车辆',
        incentivesTitle: '激励计划',
        noMatchingIncentives: '无匹配激励计划',
        articlesTitle: '新闻',
        noMatchingArticles: '无相关新闻',
      },
    },
  },
};

export default translations;
